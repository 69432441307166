


















import { Component, Vue, Emit } from 'vue-property-decorator'

@Component
export default class NoPollsState extends Vue {

  @Emit('createPoll')
  // tslint:disable-next-line
  createPoll() {}
}
