import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { profile } from './modules/profile'
import { polls } from './modules/polls'
import { slack } from './modules/slack'
import { templates } from './modules/templates'

Vue.use(Vuex)
export interface IRootState {
  version: string
}

const store: StoreOptions<IRootState> = {
  state: {
    version: '0.5.0',
  },
  modules: {
    profile,
    polls,
    slack,
    templates,
  },
}

export default new Vuex.Store<IRootState>(store)
