












































import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator'
import { IQuestion, IPoll, IUser, STATUSES } from '@/interfaces'
import UserSearch from '@/components/UserSearch.vue'
import UserList from '@/components/UserList.vue'
import { ISlackUser, ISlackChannel } from '../store/modules/slack'

@Component({
  components: {
    UserSearch,
    UserList,
  },
})
export default class ManageRespondents extends Vue {

  respondentsProxy: IUser[] = []
  removedRespondents: IUser[] = []
  pageFirstRenderedRespondents: boolean = true

  publicPollProxy: any = ''

  @Prop() private poll!: IPoll
  @Prop() private hasPrivateChannelToken!: boolean
  @Prop() private users!: IUser[]
  @Prop() private membersFromChannel!: IUser[]
  @Prop() private channels!: ISlackChannel[]

  @Watch('publicPoll')
  onChildChanged(val: boolean) {
    this.updatePublicPoll(val)
  }

  @Watch('membersFromChannel')
  onMembersChanged(val: IUser[]) {
    this.addUsersAsRespondents(val)
  }

  get respondents() {
    if (this.poll && this.pageFirstRenderedRespondents) {
      if (this.poll.respondents) {
        return this.poll.respondents
      }
    }
    return this.respondentsProxy
  }

  set respondents(value: IUser[]) {
    if (value) {
      this.respondentsProxy = value
    }
  }

  get publicPoll() {
    if (this.poll) {
      if (this.publicPollProxy === '') {
        return this.poll.public
      }
      return this.publicPollProxy
    }
    return false
  }

  set publicPoll(value: boolean) {
    this.publicPollProxy = value
  }

  addUserAsRespondent(user: IUser) {

    let userAlreadyExistsAsRespondent: IUser | undefined

    if (this.respondents) {
      this.respondentsProxy = Array.from(this.respondents)
      userAlreadyExistsAsRespondent = this.respondents.find((c) => c.email === user.email )
    }

    this.pageFirstRenderedRespondents = false
    if (!userAlreadyExistsAsRespondent) {
      // So the user list component updates
      this.respondentsProxy.push(user)
      // So the parent component gets updated
      this.addRespondent(user)
    }
  }

  addUsersAsRespondents(users: IUser[]) {

    if (this.pageFirstRenderedRespondents) {
      if (this.poll.respondents) {
        this.respondentsProxy = this.poll.respondents
      }
    }
    this.pageFirstRenderedRespondents = false

    let filteredUsers: IUser[] | undefined

    if (this.respondents.length > 0) {
      this.respondentsProxy = Array.from(this.respondents)

      filteredUsers = users.filter((u) => {

        const isFound: IUser | undefined = this.respondents.find((r: IUser) => {
          return r.email === u.email
        })
        if (isFound === undefined)  {
          return u
        }
      })

      filteredUsers.map((u) => {
        this.addRespondent(u)
        this.respondentsProxy.push(u)
      })
    } else {
      this.respondentsProxy = users
      users.map((u) => {
        this.addRespondent(u)
      })
    }

  }

  removeUserAsRespondent(user: IUser) {

    if (this.respondents) {
      this.respondentsProxy = Array.from(this.respondents)
      const index = this.respondents.findIndex((r: any) => {
        return r.email === user.email
      })
      this.pageFirstRenderedRespondents = false
      if (index !== -1) {
        this.removedRespondents.push(user)
        // so the user list component updates
        this.respondentsProxy.splice(index, 1)
        // so the parent component gets updated
        this.removeRespondent(user)
      }
    }
  }

  @Emit('updatePublicPoll')
  updatePublicPoll(value: boolean) {
    return value
  }

  @Emit('addRespondent')
  addRespondent(user: IUser) {
    return user
  }

  @Emit('removeRespondent')
  removeRespondent(user: IUser) {
    return user
  }

  @Emit('channelSelected')
  // tslint:disable-next-line
  channelSelected() {}

  @Emit('openAuthFlow')
  // tslint:disable-next-line
  openAuthFlow() {}

}
