


















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { ITag, tagColors } from '@/interfaces'

@Component
export default class TagColorList extends Vue {

  tagColors: string[] = tagColors

  selectedColorProxy: string = ''

  @Prop() private tag!: ITag

  get selectedColor() {
    if (this.selectedColorProxy === '') {
      if (this.tag) {
        return this.tag.color
      }
    }
    return this.selectedColorProxy
  }

  set selectedColor(value) {
    if (value) {
      this.selectedColorProxy = value
    }
  }

  @Emit('changeTagColor')
  changeTagColor(color: string) {
    this.selectedColorProxy = color
    return color
  }
 }
