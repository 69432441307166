












































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IPoll, IUser, STATUSES } from '@/interfaces'

@Component
export default class PollMenu extends Vue {

  formMenuVisible: string = ''

  @Prop() private poll!: IPoll
  @Prop() private user!: IUser

  get isOwner() {
    if (this.poll && this.user) {
      if (this.poll.owner) {
        return (this.user.email === this.poll.owner.email) ? true : false
      }
    }
  }

  get pollId() {
    return (this.poll) ? this.poll.id : ''
  }

  get isAdmin() {
    if (this.user && this.poll) {
      if (this.isOwner) {
        return true
      }
      if (this.poll.members) {
        const admin = this.poll.members.find((m) => {
          return m.email === this.user.email
        })
        return (admin) ? true : false
      }
    }
  }

  get archiveText() {
    if (this.poll) {
      return (this.poll.status === STATUSES.archived) ? 'Unarchive' : 'Archive'
    }
  }

  get showSettings() {
    if (this.$route.name) {
      return (['questions', 'responses'].includes(this.$route.name))
    }
  }

  confirmLeavePoll() {
    this.$buefy.dialog.confirm({
      title: 'Leave this form?',
      message: 'Leaving this form means you will no longer have administrator '
             + 'access. This form will no longer appear on your "All forms" list, and '
             + 'you will not be able to view responses.',
                cancelText: 'Cancel',
                confirmText: `Leave form`,
                type: 'is-primary',
      onConfirm: () => {
        this.leavePoll()
      },
    })
  }

  openMenu(pollId: string) {
    this.formMenuVisible = pollId
  }

  closeMenu() {
    this.formMenuVisible = ''
  }

  @Emit('copyPoll')
  copyPoll() {
    Vue.prototype.$ma.trackEvent({
      action: 'Copied form',
      properties: {
        formTitle: this.poll.title,
      },
    })
    return this.poll.id
  }

  @Emit('leavePoll')
  leavePoll() {
    Vue.prototype.$ma.trackEvent({
      action: 'Left form',
      properties: {
        formTitle: this.poll.title,
      },
    })
    return this.poll.id
  }

  @Emit('updatePoll')
  updatePoll() {
    let status: string = ''
    if (this.poll.status === STATUSES.archived) {
      status = STATUSES.inactive
    } else {
      status = STATUSES.archived
    }
    return status
  }

  @Emit('openSettingsModal')
  // tslint:disable-next-line
  openSettingsModal() {}
}
