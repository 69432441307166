






















import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { IQuestion } from '@/interfaces'

@Component
export default class QuestionFilter extends Vue {

  selectedQuestion: string = ''

  @Prop() private questions!: IQuestion[]

  get filteredQuestions() {
    if (this.questions) {
      return this.questions.filter((option) => {
          return option.text
              .toString()
              .toLowerCase()
              .indexOf(this.selectedQuestion.toLowerCase()) >= 0
      })
    }
  }

  @Emit('questionSelected')
  questionSelected(question: IQuestion) {
    return question
  }

}
