



































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IAnswer, ITag, ITagHighlight } from '@/interfaces'
import Avatar from '@/components/Avatar.vue'
import Highlightable from '@/components/Highlightable.vue'
import TagTab from '@/components/TagTab.vue'
import EditTagModal from '@/components/EditTagModal.vue'
import ResponseMenu from '@/components/ResponseMenu.vue'
import moment from 'moment'
import orderBy from 'lodash/orderBy'

interface ISpan {
  id: string
  content: string
  isHighlight: boolean
  tag?: ITag,
  index: number
}

@Component({
  components: {
    Avatar,
    Highlightable,
    TagTab,
    EditTagModal,
    ResponseMenu,
  },
})
export default class TaggableResponse extends Vue {

  isEditTagModalActive: boolean = false
  editingTag: ITag = {
    color: '',
    text: '',
    id: '',
  }

  reRenderDivKey: number = 0
  menuVisible: boolean = false

  @Prop() private answer!: IAnswer
  @Prop() private tags!: ITag[]

  get avatar() {
    if (this.answer) {
      return this.answer.user.avatar
    }
  }

  get name() {
    if (this.answer) {
      return this.answer.user.firstName + ' ' + this.answer.user.lastName
    }
  }

  get answerHasTags() {
    if (this.answer.taghighlightSet) {
      return (this.answer.taghighlightSet.length === 0 ) ? false : true
    }
  }

  get formattedAnswer() {
    if (!this.answerHasTags) {
      return this.answer.text
    }

    const formattedAnswer: ISpan[] = []
    if (this.answer.taghighlightSet) {

      let plainText = ''

      const characters = this.answer.text.split('')
      for (let index: number = 0; index <= characters.length - 1; index++) {

        const highlight = this.getTagInRange(index)

        if (highlight) {

          // Add untagged text before the tagged text if it exists
          if (plainText !== '') {
            formattedAnswer.push({
              id: '',
              content: plainText,
              isHighlight: false,
              index,
            })
          }
          plainText = ''

          // Add the tagged text
          formattedAnswer.push({
            id: highlight.id,
            content: highlight.content,
            isHighlight: true,
            tag: highlight.tag,
            index: highlight.startChar + 1,
          })

          index += highlight.content.length - 1
        } else {
          plainText += characters[index]
        }
      }

      // Add the untagged text at the end of the answer if it exists
      if (plainText !== '') {
        formattedAnswer.push({
          id: '',
          content: plainText,
          isHighlight: false,
          index: this.answer.text.length,
        })
      }
    }
    return orderBy(formattedAnswer, 'index')
  }

  getTagInRange(index: number) {
    if (this.answer.taghighlightSet) {
      return this.answer.taghighlightSet.find((r: any) => {
        if (index >= r.startChar && index < r.endChar) {
          return r
        }
      })
    }
  }

  formatDate(date: string) {
    return moment(date).fromNow()
  }

  editTag(tag: ITag) {
    this.editingTag = tag
    this.isEditTagModalActive = true
  }

  closeModal() {
    this.isEditTagModalActive = false
  }

  toggleMenuVisible(bool: boolean) {
    this.menuVisible = bool
  }

  onRemoveTagHighlight(highlightId: string) {
    this.closeModal()
    this.removeTagHighlight(highlightId)
  }

  onSaveTagUpdate(tag: ITag) {
    this.closeModal()
    this.saveTagUpdate(tag)
  }

  @Emit('removeTagHighlight')
  // tslint:disable-next-line
  removeTagHighlight(highlightId: string) {}

  @Emit('saveTagUpdate')
  // tslint:disable-next-line
  saveTagUpdate(tag: ITag) {}

  @Emit('addTag')
  addTag() {
    this.reRenderDivKey++
  }

  @Emit('createNewTagAndHighlight')
  createNewTagAndHighlight() {
    Vue.prototype.$ma.trackEvent({
      action: 'Tag added to response',
    })
    this.reRenderDivKey++
  }

  @Emit('archiveAnswer')
  archiveAnswer() {
    return this.answer.id
  }
}
