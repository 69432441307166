





















































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { IUser } from '@/interfaces'
import Avatar from '@/components/Avatar.vue'

@Component({
  components: {
    Avatar,
  },
})
export default class UserList extends Vue {

  @Prop() private users!: IUser[]
  @Prop() private owner!: IUser

  get usersExist() {
    if (this.users) {
      return (this.users[0]) ? true : false
    }
    return false
  }

  get tooltipMessage() {
    if (this.owner) {
      return this.owner.firstName + ' created this form and cannot be removed.'
    }
    return ''
  }

  @Emit('removeUser')
  removeUser(user: IUser) {
    return user
  }
}
