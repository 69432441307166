













import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { IUser } from '@/interfaces'
import UserFilter from '@/components/UserFilter.vue'
import { ISlackChannel } from '../store/modules/slack'

@Component({
  components: {
    UserFilter,
  },
})
export default class UserSearch extends Vue {

  @Prop() private users!: IUser[]
  @Prop() private channels!: ISlackChannel[]

  @Emit('addUser')
  // tslint:disable-next-line
  addUser() {}

  @Emit('channelSelected')
  // tslint:disable-next-line
  channelSelected() {}
}
