







import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import {IUser} from '@/interfaces'
import { Getter } from 'vuex-class'

const profileNamespace: string = 'profile'

@Component
export default class App extends Vue {
  @Getter('user', { namespace: profileNamespace }) public user!: IUser

  mounted() {
    this.updateAnalyticsIdentity(this.user)
  }

  updateAnalyticsIdentity(me: IUser) {
    // Use the user state to keep the global Segment analytics in-sync with any
    // mutations to the logged-in user (state.me), regardless of the source.
    Vue.prototype.$ma.identify({
      userId: me.id,
      options: {
        email: me.email,
        firstName: me.firstName,
        lastName: me.lastName,
        teamId: me.teamId,
      },
    })
  }
}
