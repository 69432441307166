import { render, staticRenderFns } from "./ResponseList.vue?vue&type=template&id=12aa6a16&scoped=true&"
import script from "./ResponseList.vue?vue&type=script&lang=ts&"
export * from "./ResponseList.vue?vue&type=script&lang=ts&"
import style0 from "./ResponseList.vue?vue&type=style&index=0&id=12aa6a16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12aa6a16",
  null
  
)

export default component.exports