import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { IRootState } from '../store'
import apolloClient from '../../apollo'
import gql from 'graphql-tag'
import { ITemplate } from '@/interfaces'

export interface ITemplateState {
  templates?: ITemplate[]
  templateError?: string
}

const namespaced: boolean = true

const templateState: ITemplateState = {
    templates: [],
    templateError: '',
}

export const getters: GetterTree<ITemplateState, IRootState> = {
  templates: (state) => {
    return state.templates
  },

}

export const actions: ActionTree<ITemplateState, IRootState> = {

  async getTemplates(context) {
    try {
      const result = await apolloClient.query({
        query: gql`{
          templates {
            id
            title
            description
            recurring
          }
        }`,
      })
      return context.commit('templatesLoaded', result.data.templates)
    } catch (e) {
      return context.commit('templateError', e)
    }
  },

  async createPollFromTemplate(context, { pk }) {
    try {
      const result = await apolloClient.mutate({
        mutation: gql`mutation($pk: UUID!) {
          createPollFromTemplate(pk: $pk,) {
            poll {
              id
            }
          }
        }`,
        variables: {
          pk,
        },
      })
      return result.data.createPollFromTemplate.poll
    } catch (e) {
      return context.commit('templateError', e)
    }
  },

}

export const mutations: MutationTree<ITemplateState> = {
  templatesLoaded(state, payload: ITemplate[]) {
    state.templates = payload
  },

  templateError(state, payload) {
    state.templateError = payload
  },
}

export const templates: Module<ITemplateState, IRootState> = {
  namespaced,
  state: templateState,
  getters,
  actions,
  mutations,
}
