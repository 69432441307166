






















import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator'
import { IQuestion, IPoll, IUser, STATUSES } from '@/interfaces'
import UserSearch from '@/components/UserSearch.vue'
import UserList from '@/components/UserList.vue'
import { ISlackUser, ISlackChannel } from '../store/modules/slack'

@Component({
  components: {
    UserSearch,
    UserList,
  },
})
export default class ManageAdministrators extends Vue {

  administratorsProxy: IUser[] = []
  removedadministrators: IUser[] = []
  pageFirstRenderedadministrators: boolean = true

  @Prop() private poll!: IPoll
  @Prop() private users!: IUser[]
  @Prop() private channels!: ISlackChannel[]
  @Prop() private administratorsFromChannel!: IUser[]

  @Watch('administratorsFromChannel')
  onAdministatorsChanged(val: IUser[]) {
    this.addUsersAsAdministrators(val)
  }


  get administrators() {
    if (this.poll && this.pageFirstRenderedadministrators) {
      if (this.poll.members) {
        return this.poll.members
      }
    }
    return this.administratorsProxy
  }

  set administrators(value: IUser[]) {
    if (value) {
      this.administratorsProxy = value
    }
  }

  get owner() {
    if (this.poll) {
      return this.poll.owner
    }
  }

  addUserAsAdministrator(user: IUser) {

    let userAlreadyExistsAsAdministrator: IUser | undefined

    if (this.administrators) {
      this.administratorsProxy = Array.from(this.administrators)
      userAlreadyExistsAsAdministrator = this.administrators.find((c) => c.email === user.email )
    }

    this.pageFirstRenderedadministrators = false
    if (!userAlreadyExistsAsAdministrator) {
      // So the user list component updates
      this.administratorsProxy.push(user)
      // So the parent component gets updated
      this.addAdministrator(user)
    }
  }

  addUsersAsAdministrators(users: IUser[]) {

    this.pageFirstRenderedadministrators = false

    let filteredUsers: IUser[] | undefined

    if (this.administrators.length > 0) {
      this.administratorsProxy = Array.from(this.administrators)

      filteredUsers = users.filter((u) => {

        const isFound: IUser | undefined = this.administrators.find((r: IUser) => {
          return r.email === u.email
        })
        if (isFound === undefined)  {
          return u
        }
      })

      filteredUsers.map((u) => {
        this.addAdministrator(u)
        this.administratorsProxy.push(u)
      })
    } else {
      this.administratorsProxy = users
      users.map((u) => {
        this.addAdministrator(u)
      })
    }

  }

  removeUserAsAdministrator(user: IUser) {
    if (this.administrators) {
      this.administratorsProxy = Array.from(this.administrators)
      const index = this.administrators.findIndex((r: any) => {
        return r.email === user.email
      })
      this.pageFirstRenderedadministrators = false
      if (index !== -1) {
        this.removedadministrators.push(user)
        // so the user list component updates
        this.administratorsProxy.splice(index, 1)
        // so the parent component gets updated
        this.removeAdministrator(user)
      }
    }
  }

  @Emit('updatePublicPoll')
  updatePublicPoll(value: boolean) {
    return value
  }

  @Emit('addAdministrator')
  addAdministrator(user: IUser) {
    return user
  }

  @Emit('removeAdministrator')
  removeAdministrator(user: IUser) {
    return user
  }

  @Emit('channelSelected')
  // tslint:disable-next-line
  channelSelected() {}
}
