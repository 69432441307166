






























import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { IPoll, IUser, STATUSES } from '@/interfaces'
import { ISlackUser } from '../store/modules/slack'
import PollMenu from '@/components/PollMenu.vue'

@Component({
  components: {
    PollMenu,
  },
})
export default class PollNav extends Vue {

  isActiveProxy: any = ''

  @Prop() private poll!: IPoll
  @Prop() private user!: IUser

  get isActive() {
    if (this.poll) {
      if (this.isActiveProxy === '') {
        return (this.poll.status === 'active') ? true : false
      }
      return this.isActiveProxy
    }
  }

  set isActive(value) {
    this.isActiveProxy = value
  }

  get questions() {
    if (this.poll && this.poll.questions) {
      return this.poll.questions.filter((q) => {
        return q.status === STATUSES.active
      })
    }
  }

  get questionsExist() {
    if (this.questions) {
      if (this.questions.length === 0) {
        return false
      }
      const blankQuestions = this.questions.filter((q) => {
        return q.text === ''
      })
      return (blankQuestions.length > 0) ? false : true
    }
  }

  get toggleLabel() {
    return (this.questionsExist) ? 'Make the form active or inactive' : 'Cannot make a form with no questions active'
  }

  toggleStatus() {
    const status = (this.isActive) ? STATUSES.active : STATUSES.inactive
    this.updatePoll(status)
  }

  @Emit('copyPoll')
  // tslint:disable-next-line
  copyPoll() {}

  @Emit('leavePoll')
  // tslint:disable-next-line
  leavePoll() {}

  @Emit('updatePoll')
  // tslint:disable-next-line
  updatePoll(status: string) {
    return status
  }

  @Emit('openSettingsModal')
  // tslint:disable-next-line
  openSettingsModal() {}
}
