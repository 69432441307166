









































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { ITag, IAnswer, tagColors } from '@/interfaces'

@Component
export default class Highlightable extends Vue {

  showMenu: boolean = false

  // used to help position the toolbar
  left: number = 0
  top: number = 0

  selectedText: string = ''
  selectionRange: any

  tag: string = ''
  tagInputFocused: boolean = false
  maxLength: number = 20
  hasCounter: boolean = false

  hackHighlightEl!: HTMLSpanElement

  @Prop() private tags!: ITag[]
  @Prop() private answer!: IAnswer

  get highlightableEl() {
    if (this.$slots.default) {
      return this.$slots.default[0].elm
    }
  }

  get filteredTags() {
    if (this.tags) {
      return this.tags.filter((option) => {
        return option.text
          .toString()
          .toLowerCase()
          .indexOf(this.tag.toLowerCase()) >= 0
      })
    }
  }

  mounted() {
    window.addEventListener('mouseup', this.onMouseup)
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.onMouseup)
    this.removeHackHighlight()
  }

  closeMenu() {
    this.showMenu = false
    this.removeHackHighlight()
  }

  removeHackHighlight() {
    if (this.hackHighlightEl) {
      if (this.hackHighlightEl.classList) {
        this.hackHighlightEl.classList.remove('highlight-hack')
      }
    }
  }

  onTagInputFocused() {
    this.tagInputFocused = true
  }

  onTagInputBlur() {
    this.tagInputFocused = false
    this.removeHackHighlight()
  }

  onMouseup() {
    if (this.tagInputFocused) {

      // This is a hack to make it look like the text is still
      // selected when a user focuses the add tag input field
      // it's not the most vue-ish code but it works
      this.hackHighlightEl = document.createElement('span')
      this.hackHighlightEl.classList.add('highlight-hack')
      this.selectionRange.surroundContents(this.hackHighlightEl)

    } else {
      const selection = window.getSelection()
      if (!selection) {
        return
      }
      try {
        this.selectionRange = selection.getRangeAt(0)
      } catch (e) {
        return
      }

      const startNode = this.selectionRange.startContainer.parentNode
      const endNode = this.selectionRange.endContainer.parentNode

      if (startNode && this.highlightableEl) {
        // if the selected text is a descendent of the highlightableEl (i.e. <p>)
        if (!this.highlightableEl.contains(startNode) || !startNode.isSameNode(endNode)) {
          this.showMenu = false
          return
        }
      }
    // Get the x, y, and width of the selection
      const { left, top, width } = this.selectionRange.getBoundingClientRect()

      if (!width) {
        this.showMenu = false
        return
      }

      this.left = left + (width / 2)
      this.top = top + window.scrollY - 10
      this.selectedText = selection.toString()
      this.showMenu = true
    }
  }

  createOrUpdateTag() {
    const foundTag = this.tags.find((tag) => {
      return tag.text === this.tag
    })
    if (foundTag) {
      this.onTagSelect(foundTag)
    } else {
      const startChar = this.answer.text.indexOf(this.selectedText)
      const endChar = startChar + this.selectedText.length
      const index = Math.floor(Math.random() * Math.floor(tagColors.length))
      this.createNewTagAndHighlight({
        tagHighlight: {
          content: this.selectedText,
          startChar,
          endChar,
          answerPk: this.answer.id,
          tagPk: '',
        },
        tag: {
          text: this.tag,
          color: tagColors[index],
          pk: this.$route.params.pollId,
        },
      })
      this.tag = ''
      this.showMenu = false
    }
  }
​
  onTagSelect(tag: ITag) {
    if (this.answer) {
      // okay so this only works if the phrase being highlighted is unique.
      // TODO: figure out how to to handle multiple instances of a phrase
      const startChar = this.answer.text.indexOf(this.selectedText)
      const endChar = startChar + this.selectedText.length
      const tagHighlight = {
        content: this.selectedText,
        startChar,
        endChar,
        answerPk: this.answer.id,
        tagPk: tag.id,
      }
      this.addTag(tagHighlight)
    }
  }

  @Emit('addTag')
  // tslint:disable-next-line
  addTag(tagHighlight: any) {}

  @Emit('createNewTagAndHighlight')
  // tslint:disable-next-line
  createNewTagAndHighlight(tagAndHighlight: any) {}
}
