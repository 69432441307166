














import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import TemplateList from '@/components/TemplateList.vue'
import { ITemplate } from '@/interfaces'

@Component({
  components: {
    TemplateList,
  },
})
export default class CreatePollModal extends Vue {

  @Prop() private templates!: ITemplate[]

  @Emit('activate')
  // tslint:disable-next-line
  activate() {}

  @Emit('closeModal')
  // tslint:disable-next-line
  closeModal() {}

  @Emit('createPoll')
  // tslint:disable-next-line
  createPoll() {}
}
