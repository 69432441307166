












import { Component, Vue } from 'vue-property-decorator'
import random from 'lodash/random'

@Component
export default class ProTip extends Vue {

  proTips = [
    {
      text: 'While you wait, did you know that by selecting your response text, you can create a tag!',
      image: 'https://storage.googleapis.com/wulu-frontend/static/images/tip-tagging-2.gif',
      alt: 'Tagging responses',
    },
  ]

  index = random(0, (this.proTips.length - 1 ))
}
