import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { IRootState } from '../store'
import apolloClient from '../../apollo'
import gql from 'graphql-tag'

export interface ISlackState {
  channels?: ISlackChannel[],
  channelError?: string,
  users?: ISlackUser[],
  userError?: string,
}

export interface ISlackChannel {
  id: string,
  name: string,
  isPrivate?: boolean,
}

export interface ISlackUser {
  id: string,
  name: string,
  avatar: string,
  email: string,
}

const namespaced: boolean = true

const pollState: ISlackState = {
    channels: [],
    channelError: '',
    users: [],
    userError: '',
}

export const getters: GetterTree<ISlackState, IRootState> = {
  channels: (state) => {
    return state.channels
  },

  users: (state) => {
    return state.users
  },
}

export const actions: ActionTree<ISlackState, IRootState> = {

  async getChannels(context) {
    try {
      const result = await apolloClient.query({
        query: gql`
          query {
            getChannels {
              id
              name
              isPrivate
            }
          }`,
      })
      return context.commit('channelsLoaded', result.data.getChannels)
    } catch (e) {
      return context.commit('channelError', e)
    }
  },

  async getUsers(context) {
    try {
      const result = await apolloClient.query({
        query: gql`
          query {
            getUsers {
              id
              name
              avatar
              email
            }
          }`,
      })
      return context.commit('usersLoaded', result.data.getUsers)
    } catch (e) {
      return context.commit('userError', e)
    }
  },

  async getMembersForChannel(context, channel ) {
    try {
      const result = await apolloClient.query({
        query: gql`
          query($channel: String!) {
            getMembersForChannel(channel: $channel) {
              id
              name
              avatar
              email
            }
          }`,
          variables: {
            channel,
          },
      })
      return result.data.getMembersForChannel
    } catch (e) {
      return context.commit('channelError', e)
    }
  },
}

export const mutations: MutationTree<ISlackState> = {
  channelsLoaded(state, payload: ISlackChannel[]) {
      state.channelError = ''
      state.channels = payload
  },
  channelError(state, payload) {
      state.channelError = payload
  },

  usersLoaded(state, payload: ISlackUser[]) {
    state.userError = ''
    state.users = payload
  },
  userError(state, payload) {
    state.userError = payload
  },
}

export const slack: Module<ISlackState, IRootState> = {
  namespaced,
  state: pollState,
  getters,
  actions,
  mutations,
}
