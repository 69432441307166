




































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class Banner extends Vue {

  @Prop() private titleMessage!: string
  @Prop() private subtitleMessage!: string
  @Prop() private icon!: string
  @Prop() private actionText!: string
  @Prop() private closable!: boolean

  @Emit('closeBanner')
  // tslint:disable-next-line
  closeBanner() {}

  @Emit('actionTextClicked')
  // tslint:disable-next-line
  actionTextClicked() {}
}

