import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter } from 'vuex-class'
import { IUser } from '@/interfaces'

const namespace: string = 'profile'
const profileNamespace: string = 'profile'

// You can declare a mixin as the same style as components.
@Component
export default class AuthorizedPage extends Vue {

  @Getter('isLoggedIn', { namespace }) public isLoggedIn!: boolean
  @Getter('user', { namespace: profileNamespace }) public user!: IUser

  unauthedPages: string[] = [
    'login',
  ]

  mounted() {
    this.routeUnauthorizedUser()
    this.routeUserToInstallPage()
  }

  get kickOutUser() {
    const onUnauthedPage = this.unauthedPages.includes(this.$route.name || '')
    if (!this.isLoggedIn && !onUnauthedPage) {
      return true
    }
    return false
  }

  get slackIsInstalled() {
    return (this.user.slackIntegration === 'True') ? true : false
  }

  routeUnauthorizedUser() {
    if (this.kickOutUser) {
      this.$router.push({ name: 'login' })
    }
  }

  routeUserToInstallPage() {
    if (!this.slackIsInstalled && this.isLoggedIn) {
      window.location.href = '/slack/install/start'
    }
  }
}
