






























import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { ITag } from '@/interfaces'

@Component
export default class TagFilter extends Vue {

  selectedTag: string = ''

  @Prop() private tags!: ITag[]

  get filteredTags() {
    if (this.tags) {
      return this.tags.filter((option) => {
          return option.text
              .toString()
              .toLowerCase()
              .indexOf(this.selectedTag.toLowerCase()) >= 0
      })
    }
  }

  @Emit('tagSelected')
  tagSelected(tag: ITag) {
    return tag
  }

}
