
























































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { IPoll, IUser } from '@/interfaces'
import moment from 'moment'

@Component({
  components: {

  },
})
export default class PollHeroBanner extends Vue {

  @Prop() private poll!: IPoll

  get title() {
    return (this.poll) ? this.poll.title : ''
  }

  get description() {
    return (this.poll) ? this.poll.description : ''
  }

  get owner() {
    if (this.poll) {
      return (this.poll.owner) ? this.poll.owner.firstName : ''
    }
  }

  get recurring() {
    return (this.poll) ? this.poll.recurring : false
  }

  get recurringText() {
    if (this.recurring) {
      return 'Form is recurring'
    } else {
      return 'Form is not recurring'
    }
  }

  get publicPoll() {
    return (this.poll) ? this.poll.public : false
  }

  get age() {
    return (this.poll) ? moment(this.poll.createdAt).fromNow() : ''
  }

  @Emit('openModal')
  // tslint:disable-next-line
  openModal() {}

  @Emit('sendForm')
  // tslint:disable-next-line
  sendForm() {}

  @Emit('sendTest')
  // tslint:disable-next-line
  sendTest() {}

  @Emit('openSettingsModal')
  openSettingsModal(tab: string) {
    return tab
  }
}
