import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { IRootState } from '../store'
import gql from 'graphql-tag'
import isEmpty from 'lodash/isEmpty'
import apolloClient from '../../apollo'
import { IUser } from '@/interfaces'

export interface IProfileState {
  user?: IUser
  error?: boolean
  team?: IUser[]
}

const profileState: IProfileState = {
  user: (window as any).wulu.user || {
    email: null,
    id: null,
  },
  error: false,
  team: [],
}

const namespaced: boolean = true

export const getters: GetterTree<IProfileState, IRootState> = {
  isLoggedIn: (state) => {
    if (!isEmpty(state.user)) {
      return true
    }
    return false
  },

  team: (state) => {
    return state.team
  },

  user: (state) => {
    return state.user
  },

}

export const actions: ActionTree<IProfileState, IRootState> = {

  // This is not current used because we use Slack OAuth
  async login(context, { email, password }) {
    try {
      const result = await apolloClient.mutate({
        mutation: gql`mutation($email: String!, $password: String! ) {
          login(email: $email, password: $password) {
            user {
              email
            }
          }
        }`,
        variables: {
          email,
          password,
        },
      })
      return context.commit('profileLoaded', result.data.login.user)
    } catch (e) {
      throw(e)
    }
  },

  async getAllUsers(context) {
    try {
      const result = await apolloClient.query({
        query: gql`query {
          allUsers {
            email
            avatar
            firstName
          }
        }`,
      })
      return context.commit('teamLoaded', result.data.allUsers)
    } catch (e) {
      throw(e)
    }
  },
}

export const mutations: MutationTree<IProfileState> = {
    profileLoaded(state, payload: IUser) {
      state.error = false
      state.user = payload
    },
    profileError(state) {
      state.error = true
      state.user = undefined
    },
    teamLoaded(state, payload: IUser[]) {
      state.team = payload
    },
}

export const profile: Module<IProfileState, IRootState> = {
  namespaced,
  state: profileState,
  getters,
  actions,
  mutations,
}
