import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueMultianalytics from 'vue-multianalytics'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://6a2fa0584348415f83d13f1dd5650da8@sentry.io/1546041',
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
  })
}

Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

Vue.config.productionTip = false

const segmentConfig = {
  token: process.env.NODE_ENV === 'development'
    ? 'renbXGXZJXY3qrBvwa69s090RMrQDBJ7' : 'DCs6Giizw0NpZsITlDj4Gtjo8aiQUVMt',
}

Vue.use(VueMultianalytics, {
  modules: {
    segment: segmentConfig,
  },
  routing: {
    vueRouter: router,
     preferredProperty: 'name',
  },
})



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
