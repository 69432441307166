


























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import ManageRespondents from '@/components/ManageRespondents.vue'
import { IPoll, IUser } from '@/interfaces'
import { ISlackChannel } from '../store/modules/slack'
import ChannelSearch from '@/components/ChannelSearch.vue'
import sortBy from 'lodash/sortBy'
import ChannelList from '@/components/ChannelList.vue'

@Component({
  components: {
    ChannelList,
    ManageRespondents,
    ChannelSearch,
  },
})
export default class SendFormModal extends Vue {

  sendNotificationChannelEnabled: boolean = false

  removedRespondents: IUser[] = []
  newRespondents: IUser[] = []
  publicPollProxy: any = ''
  messageNotificationChannelList: ISlackChannel[] = []

  @Prop() private poll!: IPoll
  @Prop() private user!: IUser
  @Prop() private users!: IUser[]
  @Prop() private membersFromChannel!: IUser[]
  @Prop() private channels!: ISlackChannel[]

  get isOlarker() {
    return this.user.email.endsWith('olark.com')
  }

  get publicPoll() {
    if (this.poll) {
      if (this.publicPollProxy === '') {
        return this.poll.public
      }
      return this.publicPollProxy
    }
    return false
  }

  set publicPoll(value: boolean) {
    this.publicPollProxy = value
  }

  get noChannelError() {
    if (this.sendNotificationChannelEnabled && this.messageNotificationChannelList.length < 1) {
      return true
    }
    return false
  }

  get sendFormDisabled(): boolean {
    return this.numOfRespondents === 0 ||
            (this.sendNotificationChannelEnabled && this.messageNotificationChannelList.length === 0)
  }


  get respondents() {
    if (this.poll) {
      if (this.poll.respondents) {
        return this.poll.respondents
      }
    }
  }

  get numOfRespondents() {
    if (this.respondents && !this.publicPoll) {

      let numOfRespondents = this.respondents.length

      if (this.newRespondents.length !== 0) {
        numOfRespondents += this.newRespondents.length
      }

      if (this.removedRespondents.length !== 0) {
        numOfRespondents -= this.removedRespondents.length
      }

      return numOfRespondents
    }
    if (this.publicPoll) {
      if (this.users) {
        return this.users.length
      }
    }
    return 0
  }

  get sendFormText() {
    return 'Send form'
  }

  get hasPrivateChannelToken() {
    if (this.user) {
      if (this.user.hasPrivateToken === 'False') {
        return false
      } else {
        return true
      }
    }
    return false
  }

  addRespondent(user: IUser) {
    this.newRespondents.push(user)
  }

  removeRespondent(user: IUser) {
    if (this.respondents) {

      const userExistsAsRespondent = this.respondents.findIndex((r: IUser) => {
          return r.email === user.email
      })

      if (userExistsAsRespondent !== -1) {

        if (this.removedRespondents.length > 0) {

          // Has the user already been removed?
          const userExistsAsRemovedRespondent = this.removedRespondents.findIndex((r: IUser) => {
            return r.email === user.email
          })
          if (userExistsAsRemovedRespondent === -1) {
            this.removedRespondents.push(user)
          }
        } else {
          this.removedRespondents.push(user)
        }
      }
    }

    if (this.newRespondents.length > 0) {
      const userExistsAsUnsavedRespondent = this.newRespondents.findIndex((r: IUser) => {
        return r.email === user.email
      })
      if (userExistsAsUnsavedRespondent !== -1) {
        this.newRespondents.splice(userExistsAsUnsavedRespondent, 1)
      }
    }
  }

  updatePublicPoll(value: boolean) {
    this.publicPoll = value
  }

  openAuthFlow() {
    window.location.href = '/slack/user/start'
  }


  get sortedChannels() {
    return (this.channels) ? sortBy(this.channels, ['name']) : []
  }

  addSlackChannel(channel: ISlackChannel) {
    this.messageNotificationChannelList = [channel]
  }

  removeSlackChannel(channel: ISlackChannel) {
    this.messageNotificationChannelList = []
  }

  sendNotificationChannelClicked(value: boolean) {
      if (value === false) {
        this.messageNotificationChannelList = []
      }
  }

  @Emit('closeModal')
  // tslint:disable-next-line
  closeModal() {}

  @Emit('channelSelected')
  // tslint:disable-next-line
  channelSelected() {}

  @Emit('sendForm')
  sendForm() {
    return {
      newRespondents: this.newRespondents,
      removedRespondents: this.removedRespondents,
      publicPoll: this.publicPoll,
      sendMessageNotificationChannel: this.sendNotificationChannelEnabled ?
              this.messageNotificationChannelList[0].id : '',
    }
  }

}
