















































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { ISlackChannel } from '../store/modules/slack'

@Component
export default class ChannelSearch extends Vue {

  channel: string = ''

  @Prop() private channels!: ISlackChannel[]
  @Prop() private label!: string
  @Prop() private disabled!: boolean

  get filteredChannels() {
    return this.channels.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.channel.toLowerCase()) >= 0
    })
  }

  optionSelected(option: ISlackChannel) {
    if (option) {
      this.addChannel(option)
    }
  }

  addChannel(option?: ISlackChannel) {
    let channelName = this.channel
    if (option && 'name' in option) {
      channelName = option.name
    }
    const foundChannel = this.channels.find((c) => {
      return c.name === channelName.toLowerCase()
    })
    if (foundChannel) {
      this.$nextTick(() => {
        this.channel = ''
      })
      this.addSlackChannel(foundChannel)
    }
  }

  @Emit('addSlackChannel')
  addSlackChannel(channel: ISlackChannel) {
    return channel
  }

}
