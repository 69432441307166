



































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IPoll, IQuestion, STATUSES } from '@/interfaces'
import maxBy from 'lodash/maxBy'

@Component
export default class PollForm extends Vue {

  titleCharLimit: number = 24
  descriptionCharLimit: number = 100
  titleProxy: string = '-1'
  descriptionProxy: string | undefined = '-1'

  hasTitleError: boolean = false
  updatePollHasEmitted: boolean = false

  @Prop() private poll!: IPoll

  get title() {
    if (this.poll) {
      return (this.titleProxy === '-1') ? this.poll.title : this.titleProxy
    }
    return ''
  }
  set title(value) {
    this.titleProxy = value
  }

  get description() {
    if (this.poll) {
      return (this.descriptionProxy === '-1') ? this.poll.description : this.descriptionProxy
    }
  }
  set description(value) {
    this.descriptionProxy = value
  }

  validateAndSave() {
    if (this.updatePollHasEmitted) {
      if (this.title.length === 0) {
        this.hasTitleError = true
      } else {
        this.save()
      }
    } else {
      this.closeModal()
    }
  }

  @Emit('save')
  // tslint:disable-next-line
  save() {}

  @Emit('closeModal')
  // tslint:disable-next-line
  closeModal() {}

  @Emit('updatePoll')
  updatePoll() {
    this.updatePollHasEmitted = true
    return {
      pk: this.poll.id,
      title: this.title,
      description: this.description,
      status: this.poll.status,
      public: this.poll.public,
    }
  }
}
