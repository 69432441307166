










































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class SendNotificationModal extends Vue {

  @Prop() private sendToAll!: boolean

  get sendToWhoMessage() {
    if (this.sendToAll) {
      return 'A notification will be sent to all respondents'
    }
    return 'A notification will be sent to everyone that has not yet responded'
  }

  @Emit('sendNotification')
  // tslint:disable-next-line
  sendNotification() {
    return this.sendToAll
  }

  @Emit('closeModal')
  // tslint:disable-next-line
  closeModal() {}
}
