





















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IQuestion, STATUSES } from '@/interfaces'
import QuestionForm from '@/components/QuestionForm.vue'
import sortBy from 'lodash/sortBy'

@Component({
  components: {
    QuestionForm,
  },
})
export default class QuestionList extends Vue {

  @Prop() private questions!: IQuestion[]
  @Prop() private focusNewQuestion!: boolean

  get filteredQuestions() {
    if (this.questions) {
      return sortBy(this.questions.filter((q) => {
        return q.status !== STATUSES.archived
      }), ['createdAt'])
    }
    return []
  }

  formatQuestionTitle(question: string) {
    const afterText = (question.length > 100) ? '...' : ''
    return question.slice(0, 100) + afterText
  }

  shouldQuestionGetFocus(index: number) {
    return this.focusNewQuestion && index === (this.filteredQuestions.length - 1)
  }

  @Emit('updateQuestion')
  // tslint:disable-next-line
  updateQuestion(question: IQuestion) {}

  @Emit('deleteQuestion')
  // tslint:disable-next-line
  deleteQuestion(id: string) {}

  @Emit('removeUnsavedQuestion')
  // tslint:disable-next-line
  removeUnsavedQuestion() {}
}
