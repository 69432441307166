




















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IPoll, IUser, STATUSES } from '@/interfaces'
import PollMenu from '@/components/PollMenu.vue'

@Component({
  components: {
    PollMenu,
  },
})
export default class PollCard extends Vue {

  statuses = STATUSES
  isActiveProxy: any = ''

  archiveStatus: string = this.statuses.archived

  @Prop() private poll!: IPoll
  @Prop() private user!: IUser

  mounted() {
    if (this.poll.status === this.statuses.active) {
      this.isActive = true
    }
    if (this.poll.status === this.statuses.archived) {
      this.archiveStatus = this.statuses.inactive
    }
  }

  get description() {
    if (this.poll.description) {
      const text = this.poll.description
      return (text.length < 50) ? text : text.slice(0, 50) + '...'
    }
  }

  get isActive() {
    if (this.poll) {
      if (this.isActiveProxy === '') {
        return (this.poll.status === 'active') ? true : false
      }
      return this.isActiveProxy
    }
  }

  set isActive(value) {
    this.isActiveProxy = value
  }

  get publicPoll() {
    if (this.poll) {
      return this.poll.public
    }
  }

  get recurringPoll() {
    if (this.poll) {
      return this.poll.recurring
    }
  }

  get doesPollHaveQuestions() {
    if (this.poll.questions) {
      return (this.poll.questions.filter((q) => {
        return q.status === STATUSES.active
      })).length > 0 ? true : false
    }
    return false
  }


  get toggleMessage() {
    return (this.doesPollHaveQuestions) ?
    'Turn on/off' : 'Cannot make a form with no questions active'
  }

  toggleStatus() {
    const status = (this.isActive) ? STATUSES.active : STATUSES.inactive

    if (this.isActive) {
      this.$buefy.dialog.confirm({
        title: 'Make your form active?',
        message: `Making your form "Active" will allow respondents to
                  access your form`,
                  cancelText: 'Cancel',
                  confirmText: `Let's do this!`,
                  type: 'is-primary',
        onConfirm: () => {
          this.updatePoll(status)
        },
        onCancel: () => {
          this.isActiveProxy = false
        },
      })
    } else {
      this.updatePoll(status)
    }
  }

  @Emit('updatePoll')
  updatePoll(status: string) {
    return {
      id: this.poll.id,
      questions: this.poll.questions,
      status,
    }
  }

  @Emit('copyPoll')
  // tslint:disable-next-line
  copyPoll() {}

  @Emit('leavePoll')
  // tslint:disable-next-line
  leavePoll() {}
}
