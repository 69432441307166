







































import { Vue, Prop, Component, Emit, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { State, Action, Getter } from 'vuex-class'
import { STATUSES, IUser } from '@/interfaces'
import UserFilter from '@/components/UserFilter.vue'
import StatusFilter from '@/components/StatusFilter.vue'

@Component({
  components: {
    UserFilter,
    StatusFilter,
  },
})
export default class PollFilters extends Vue {

  statuses = STATUSES

  filters = {
    status: 'all',
    user: 'all',
  }

  channels = []

  @Prop() private users!: IUser[]
  @Prop() private clearFilters!: number
  @Prop() private disabled!: boolean

  @Watch('clearFilters')
   onClearFiltersUpdate(value: string) {
    this.filters = {
      status: 'all',
      user: 'all',
    }
  }

  get userTag() {
    if (this.filters.user !== 'all') {
      return this.filters.user
    }
    return ''
  }

  get statusTag() {
    if (this.filters.status !== 'all') {
      return this.filters.status
    }
    return ''
  }

  userSelected(user: IUser) {
    this.filters.user = user.email
    this.filter()
  }

  resetUserFilter() {
    this.filters.user = 'all'
    this.filter()
  }

  statusSelected(status: string) {
    if (status === null) {
      return
    }
    this.filters.status = status
    this.filter()
  }

  resetStatusFilter() {
    this.filters.status = 'all'
    this.filter()
  }

  @Emit('filter')
  filter() {
    return this.filters
  }
}
