import fetch from 'unfetch'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import Cookies from 'js-cookie'

// HTTP connectionto the API
const httpLink = new HttpLink({
  uri: '/graphql/',
  credentials: 'include',
  fetch,
})

// Cache implementation
const cache = new InMemoryCache()

const csrfMiddleware = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-CSRFToken': Cookies.get('csrftoken') || '',
    },
  }
})

// Create the apollo client
const apolloClient = new ApolloClient({
  link: csrfMiddleware.concat(httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  } as any,
})

export default apolloClient
