import Vue from 'vue'
import Router from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import PollsView from '@/views/PollsView.vue'
import PollView from '@/views/PollView.vue'
import PollQuestionsView from '@/views/PollQuestionsView.vue'
import PollResponsesView from '@/views/PollResponsesView.vue'
import PageNotFoundView from '@/views/PageNotFoundView.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/forms',
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/forms',
      name: 'forms',
      component: PollsView,
    },
    {
      path: '/forms/:pollId',
      name: 'poll',
      component: PollView,
      children: [
        {
          name: 'questions',
          path: 'questions/:modal?/:tab?',
          component: PollQuestionsView,
        },
        {
          name: 'responses',
          path: 'responses/:modal?/:tab?',
          component: PollResponsesView,
        },
      ],
    },
    {
      path: '*',
      name: 'pageNotFound',
      component: PageNotFoundView,
    },
  ],
})
