



















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IPoll } from '@/interfaces'
import PollForm from '@/components/PollForm.vue'

interface IPollPayload {
  title: string
  description: string
}

@Component({
  components: {
    PollForm,
  },
})
export default class UpdatePollModal extends Vue {

  updatedPoll: IPollPayload = {
    title: '',
    description: '',
  }

  @Prop() private poll!: IPoll

  updatePoll(poll: IPoll) {
    this.updatedPoll = poll
  }

  @Emit('closeModal')
  // tslint:disable-next-line
  closeModal() {}

  @Emit('save')
  save() {
    return this.updatedPoll
  }
}
