

































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PageNotFoundView extends Vue {

  lostGifs: string[] = [
    'http://giphygifs.s3.amazonaws.com/media/FPjbHO0jJxGsE/giphy.gif', // Gandalf lost
    'http://giphygifs.s3.amazonaws.com/media/3nskIst9QtSMg/giphy.gif', // Lost
    'http://giphygifs.s3.amazonaws.com/media/mPytjcsG3XS4o/giphy.gif', // Dumbo lost
    'https://media.giphy.com/media/aCEmNrfMsyELe/giphy.gif', // kpop lost
    'https://media.giphy.com/media/3o7aTskHEUdgCQAXde/giphy.gif', // travolta lost
    'https://media.giphy.com/media/3oz8xOWhuVpr3Kw3WE/giphy.gif', // basketball wives lost
    'https://media.giphy.com/media/13EjnL7RwHmA2Q/giphy.gif', // angry panda
    'https://media.giphy.com/media/3boPPdHk2ueo8/giphy.gif', // ipad puppy
  ]

  getRandomImage() {
    const index = Math.floor(Math.random() * Math.floor(this.lostGifs.length))
    return this.lostGifs[index]
  }

}
