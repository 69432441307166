











































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { ITag } from '@/interfaces'
import TagColorList from '@/components/TagColorList.vue'

@Component({
  components: {
    TagColorList,
  },
})
export default class EditTagModal extends Vue {

  colorProxy: string = ''
  hasCounter: boolean = true
  tagNameProxy: string = ''

  @Prop() private tag!: ITag

  get tagName() {
    if (this.tagNameProxy === '') {
      if (this.tag) {
        return this.tag.text
      }
    }
    return this.tagNameProxy
  }

  set tagName(value) {
    if (value) {
      this.tagNameProxy = value
    }
  }

  get color() {
    if (this.colorProxy === '') {
      if (this.tag) {
        return this.tag.color
      }
    }
    return this.colorProxy
  }

  set color(value: string) {
    if (value) {
      this.colorProxy = value
    }
  }

  changeTagColor(color: string) {
    this.colorProxy = color
  }

  @Emit('deleteTag')
  deleteTag() {
    return this.tag.id
  }

  @Emit('closeModal')
  // tslint:disable-next-line
  closeModal() {}

  @Emit('save')
  save() {
    return {
      pk: this.tag.id,
      color: this.color,
      text: this.tagName,
    }
  }
}
