



































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IAnswer } from '@/interfaces'
import Avatar from '@/components/Avatar.vue'
import ResponseMenu from '@/components/ResponseMenu.vue'
import moment from 'moment'

@Component({
  components: {
    Avatar,
    ResponseMenu,
  },
})
export default class PlainTextResponse extends Vue {

  menuVisible: boolean = false

  @Prop() private answer!: IAnswer
  @Prop() private type!: string

  get avatar() {
    if (this.answer) {
      return this.answer.user.avatar
    }
  }

  get name() {
    if (this.answer) {
      return this.answer.user.firstName + ' ' + this.answer.user.lastName
    }
  }

  formatDate(date: string) {
    return moment(date).fromNow()
  }

  toggleMenuVisible(bool: boolean) {
    this.menuVisible = bool
  }

  @Emit('archiveAnswer')
  archiveAnswer() {
    return this.answer.id
  }
}
