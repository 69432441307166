
























































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { FIELDS, STATUSES, IQuestion } from '@/interfaces'
import sortBy from 'lodash/sortBy'

interface IPollPayload {
  pk: string
  title: string
  description: string,
  status: string
}

@Component
export default class PollPreview extends Vue {

  fieldTypes = {
    select: [ FIELDS.user, FIELDS.channel, FIELDS.dropdown],
    textarea: [ FIELDS.textarea ],
    textfield: [ FIELDS.textfield ],
  }

  @Prop() private pollDetails!: IPollPayload
  @Prop() private propQuestions!: IQuestion[]

  get questions() {
    if (this.propQuestions) {
      return sortBy(this.propQuestions.filter((q) => q.status !== STATUSES.archived), ['createdAt'])
    }
  }

  get title() {
    if (this.pollDetails) {
      return this.pollDetails.title
    }
  }
}
