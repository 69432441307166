














import { Component, Vue } from 'vue-property-decorator'
import ProTip from '@/components/ProTip.vue'

@Component({
  components: {
    ProTip,
  },
})
export default class NoResponsesState extends Vue {

}
