

















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IAnswer, IQuestion, IChoice } from '@/interfaces'
import ResponseModal from '@/components/ResponseModal.vue'

@Component({
  components: {
    ResponseModal,
  },
})
export default class ResponseMenu extends Vue {

  menuVisible: boolean = false
  responseModalVisible: boolean = false

  @Prop() private answer!: IAnswer
  @Prop() private question!: IQuestion
  @Prop() private choice!: IChoice
  @Prop() private answers!: IAnswer[]

  get doesChoiceExist() {
    return (this.choice) ? true : false
  }

  openMenu() {
    this.menuVisible = true
  }

  closeMenu() {
    this.menuVisible = false
  }

  toggleResponseModal(bool: boolean) {
    this.responseModalVisible = bool
  }

  @Emit('archiveAnswer')
  // tslint:disable-next-line
  archiveAnswer() {}

  @Emit('archiveModalAnswer')
  // tslint:disable-next-line
  archiveModalAnswer() {}
}
