


































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TopNav extends Vue {

  public showMobileNav: boolean = false

  @Prop() private email!: string

  get activeLink() {
    return this.$route.name
  }

}
