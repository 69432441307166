










import { Component, Vue, Emit } from 'vue-property-decorator'

@Component
export default class NoPollsFound extends Vue {

@Emit('clearFilters')
// tslint:disable-next-line
clearFilters() {}
}
