










































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IChoice, IAnswer } from '../interfaces'
import Avatar from '@/components/Avatar.vue'

@Component({
  components: {
    Avatar,
  },
})
export default class ResponseModal extends Vue {

  @Prop() private choice!: any
  @Prop() private answers!: IAnswer[]

  get choiceText() {
    if (this.choice.hasOwnProperty('value')) {
      return this.choice.value
    }
    if (this.choice.hasOwnProperty('displayText')) {
      return this.choice.displayText
    }
  }

  @Emit('archiveAnswer')
  // tslint:disable-next-line
  archiveAnswer(responseId: string) {
    return responseId
  }

  @Emit('closeModal')
  // tslint:disable-next-line
  closeModal() {}
}
