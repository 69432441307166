


















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IAnswer, IChoice } from '@/interfaces'
import Avatar from '@/components/Avatar.vue'
import ResponseMenu from '@/components/ResponseMenu.vue'
import moment from 'moment'

@Component({
  components: {
    Avatar,
    ResponseMenu,
  },
})
export default class DropdownResponse extends Vue {

  menuVisible: string = ''

  @Prop() private answers!: IAnswer[]
  @Prop() private choices!: IChoice[]

  get numOfAnswers() {
    if (this.answers) {
      return this.answers.length
    }
  }

  getAvatar(choiceId: string) {
    if (this.answers) {
      const choiceAnswers = this.findAnswer(choiceId)
      if (choiceAnswers.length > 0) {
        return choiceAnswers[0].user.avatar
      }
      return ''
    }
  }

  getNameForChoice(choiceId: string) {
    if (this.answers) {
      const choiceAnswers = this.findAnswer(choiceId)
      if (choiceAnswers.length > 0) {
        if (choiceAnswers.length === 1) {
          return choiceAnswers[0].user.firstName + ' ' + choiceAnswers[0].user.lastName
        }
        if (choiceAnswers.length === 2) {
          return choiceAnswers[0].user.firstName + ' + 1 other respondent'
        }
        return choiceAnswers[0].user.firstName + ' + ' + ( choiceAnswers.length - 1 )  + ' other respondents'
      }
    }
  }

  getValueForChoice(choiceId: string) {
    const answers: IAnswer[] = this.findAnswer(choiceId)
    return answers.length
  }

  getAnswersForChoice(choiceId: string) {
    return this.answers.filter((choice) => {
      return choice.text === choiceId
    })
  }

  getAnswersExistForChoice(choiceId: string) {
    const answers = this.answers.filter((choice) => {
      return choice.text === choiceId
    })
    return (answers.length > 0) ? true : false
  }

  getPercentage(choiceId: string) {
    if (this.numOfAnswers) {
      const value = this.getValueForChoice(choiceId)
      return Math.round((value / this.numOfAnswers) * 100)
    }
  }

  findAnswer(choiceId: string) {
    return this.answers.filter((a) => {
      return a.text === choiceId
    })
  }

  toggleMenuVisible(choiceId: string) {
    this.menuVisible = choiceId
  }

  @Emit('archiveModalAnswer')
  // tslint:disable-next-line
  archiveModalAnswer() {}
}
