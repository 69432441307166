

















































import { Vue, Component, Emit } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { IPollState } from '@/store/modules/polls'
import {
  IQuestion,
  IPoll,
  STATUSES,
  IUser,
  IResponseFilters,
  IAnswer,
  ITag,
  ITagHighlight } from '@/interfaces'
import { ISlackUser } from '../store/modules/slack'
import { GetPollWithQuestions } from '@/queries/getPollQueries'
import RespondentsList from '@/components/RespondentsList.vue'
import ResponseFilters from '@/components/ResponseFilters.vue'
import ResponseList from '@/components/ResponseList.vue'
import NoResultsState from '@/components/NoResultsState.vue'
import NoResponsesState from '@/components/NoResponsesState.vue'
import SendNotificationModal from '@/components/SendNotificationModal.vue'
import WuuHuu from '@/components/WuuHuu.vue'
import uniqBy from 'lodash/uniqBy'
import omitBy from 'lodash/omitBy'
import moment from 'moment'

const slackNamespace: string = 'slack'
const pollNamespace: string = 'polls'

@Component({
  components: {
    RespondentsList,
    ResponseFilters,
    ResponseList,
    WuuHuu,
    NoResponsesState,
    NoResultsState,
    SendNotificationModal,
  },
})
export default class PollResponsesView extends Vue {
  @Getter('poll', { namespace: pollNamespace }) public poll!: IPoll
  @Getter('users', { namespace: slackNamespace }) public slackUsers!: ISlackUser[]

  isNotificationModalActive: boolean = false
  sendToAll: boolean = true

  isActiveProxy: any = ''
  filters: IResponseFilters = {
    question: 'all',
    respondent: 'all',
    tag: 'all',
    startDate: 'all',
    endDate: 'all',
  }
  clearFiltersCount: number = 0
  notificationsSent: boolean = false

  get filteredAnswersExist() {
    if (this.filteredQuestions) {
      const answersExist = this.filteredQuestions.findIndex((q: IQuestion) => {
        if (q.answers) {
          return q.answers.length > 0
        }
        return false
      })
      if (answersExist === -1) {
        return false
      }
      return true
    }
    return false
  }

  get respondents() {
    if (this.poll) {
      if (this.poll.public) {
        return this.slackUsers.map((u) => {
          return {
            firstName: u.name,
            avatar: u.avatar,
            email: u.email,
          }
        })
      } else {
        return this.poll.respondents
      }
    }
  }

  get usersWhoHaveResponded() {
    if (this.responses) {

      const uniqResponses = uniqBy(this.responses, (r) => {
        if (!!r.user) {
          return r.user.email
        }
      })

      if (this.poll.public) {

        return uniqResponses

      } else {

        const responsesWithRespondents = uniqResponses.filter((response) => {
          if (this.respondents) {

            const foundRespondent = this.respondents.find((respondent) => {

              return response.user.email === respondent.email

            })
            if (foundRespondent) {
              return response
            }
          }
        })
        return responsesWithRespondents
      }
    }
  }

  get questions() {
    if (this.poll && this.poll.questions) {
      return this.poll.questions.filter((q) => {
        return q.status === STATUSES.active
      })
    }
  }

  get questionsExist() {
    return (this.questions && this.questions.length > 0) ? true : false
  }

  get toggleLabel() {
    return (this.questionsExist) ? 'Turn on/off' : 'Cannot make a form with no questions active'
  }

  get responses() {
    const responses: IAnswer[] = []
    if (this.questions) {
      this.questions.map((q) => {
        if (q.answers) {
          q.answers.map((a) => {
            responses.push(a)
          })
        }
      })
      return responses
    }
  }

  get activeResponsesExist() {
    if (this.responses) {
      const responses = this.responses.filter((r) => {
        return r.status === STATUSES.active
      })
      return responses.length > 0
    }
  }

  get numOfRespondents() {
    if (this.respondents) {
      return this.respondents.length
    }
  }

  get filteredQuestions() {
    if (this.questions) {
      let clonedQuestions = JSON.parse(JSON.stringify(this.questions))
      clonedQuestions = clonedQuestions.map((q: IQuestion) => {
        if (q.answers && q.answers.length > 0) {
            const answers: any = q.answers.filter((a) => {
              return a.status === 'active'
            })
            q.answers = answers
            return q
        } else {
          return q
        }
      })
      const questionFilters = omitBy(this.filters, (value: string, key: string) => {
        return value === 'all'
      })

      for (const property in questionFilters) {
        if (questionFilters.hasOwnProperty(property)) {
          if (property === 'question') {
            clonedQuestions = this.filterByQuestion(clonedQuestions)
          }
          if (property === 'respondent') {
            clonedQuestions = this.filterByRespondent(clonedQuestions)
          }
          if (property === 'tag') {
            clonedQuestions = this.filterByTag(clonedQuestions)
          }
          if (property === 'startDate') {
            clonedQuestions = this.filterByStartDate(clonedQuestions)
          }
          if (property === 'endDate') {
            clonedQuestions = this.filterByEndDate(clonedQuestions)
          }
        }
      }
      return clonedQuestions
    }
  }

  get areFiltersOn() {
    if (this.filters) {
      let bool = false
      Object.entries(this.filters).forEach(([key, value]) => {
        if (value !== 'all') {
          bool = true
        }
      })
      return bool
    }
    return false
  }

  get publicPoll() {
    if (this.poll) {
      return this.poll.public
    }
  }

  get tags() {
    if (this.poll) {
      return this.poll.tags
    }
  }

  filterByQuestion(clonedQuestions: IQuestion[]) {
    return [clonedQuestions.find((q: IQuestion) => {
      return q.id === this.filters.question
    })]
  }

  filterByRespondent(clonedQuestions: IQuestion[]) {
    return clonedQuestions.map((q: IQuestion) => {
      if (q.answers) {
        const answers = q.answers.filter((a: IAnswer) => {
          return a.user.email === this.filters.respondent
        })
        q.answers = answers
        return q
      }
    })
  }

  filterByTag(clonedQuestions: IQuestion[]) {
    return clonedQuestions.map((q: IQuestion) => {
      if (q.answers) {
        const answers = q.answers.filter((a: IAnswer) => {
          if (a.taghighlightSet) {
            const tagHighlights = a.taghighlightSet.filter((tagHighlight: ITagHighlight) => {
              return tagHighlight.tag.id === this.filters.tag
            })
            if (tagHighlights.length > 0) {
              return a
            }
          }
        })
        q.answers = answers
        return q
      }
    })
  }

  filterByStartDate(clonedQuestions: IQuestion[]) {
    return clonedQuestions.map((q: IQuestion) => {
      if (q.answers) {
        const answers = q.answers.filter((a: IAnswer) => {
          return moment(a.createdAt).isSameOrAfter(this.filters.startDate, 'day')
        })
        q.answers = answers
        return q
      }
    })
  }

  filterByEndDate(clonedQuestions: IQuestion[]) {
    return clonedQuestions.map((q: IQuestion) => {
      if (q.answers) {
        const answers = q.answers.filter((a: IAnswer) => {
          return moment(a.createdAt).isSameOrBefore(this.filters.endDate, 'day')
        })
        q.answers = answers
        return q
      }
    })
  }

  closeNotificationModal() {
    this.isNotificationModalActive = false
  }

  filter(filters: IResponseFilters) {
    this.filters = filters
  }

  resetFilters() {
    this.filters = {
      question: 'all',
      respondent: 'all',
      tag: 'all',
      startDate: 'all',
      endDate: 'all',
    }
    this.clearFiltersCount++
  }

  async sendNotificationToRespondents(sendToAll: boolean) {
    Vue.prototype.$ma.trackEvent({
      action: 'Sent notification to respondents',
      properties: {
        sentToAll: sendToAll,
      },
    })
    this.isNotificationModalActive = false
    await this.$store.dispatch('polls/sendNotificationToRespondents', {
      input: {
        pk: this.poll.id,
        sendToAll,
      },
    })
    this.notificationsSent = true
    this.$buefy.toast.open({
      message: 'Notifications sent!',
      type: 'is-success',
    })
  }

  async getPoll() {
    await this.$store.dispatch('polls/getPoll', {
      pk: this.$route.params.pollId,
      queryType: GetPollWithQuestions,
    })
  }

  async updatePoll(status: string) {
    await this.$store.dispatch('polls/updatePoll', {
      pk: this.$route.params.pollId,
      poll: {
        status,
      },
    })

    await this.getPoll()

    const message = (status === STATUSES.active) ? 'Form is active!' : 'Form is inactive!'
    this.$buefy.toast.open({
      message,
      type: 'is-success',
    })
  }

  async saveTagUpdate(updatedTag: ITag) {
    await this.$store.dispatch('polls/updateTag', {
      input: updatedTag,
    })
    await this.getPoll()
    this.$buefy.toast.open({
        message: 'Tag updated successfully!',
        type: 'is-success',
    })
  }

  async removeTagHighlight(highlightId: string) {
    this.$store.dispatch('polls/deleteTagHighlight', {
      pk: highlightId,
    })
    await this.getPoll()
  }

  async addTag(highlight: any) {
    this.$store.dispatch('polls/createTagHighlight', {
      input: highlight,
    })
    await this.getPoll()
  }

  async createNewTagAndHighlight(tagAndHighlight: any) {
    const response = await this.$store.dispatch('polls/createTag', {
      input: tagAndHighlight.tag,
    })
    tagAndHighlight.tagHighlight.tagPk = response.tag.id
    await this.$store.dispatch('polls/createTagHighlight', {
      input: tagAndHighlight.tagHighlight,
    })
    await this.getPoll()
  }

  async archiveAnswer(responseId: string) {
    await this.$store.dispatch('polls/updateResponse', {
      pk: responseId,
      input: {
        status: 'archived',
      },
    })
    await this.getPoll()
  }

  @Emit('openSettingsModal')
  // tslint:disable-next-line
  openSettingsModal() {}

  @Emit('sendNotification')
  // tslint:disable-next-line
  sendNotification() {}
}
