



























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IQuestion, ITag } from '@/interfaces'
import TaggableResponse from '@/components/TaggableResponse.vue'
import AggregateResponse from '@/components/AggregateResponse.vue'
import DropdownResponse from '@/components/DropdownResponse.vue'
import PlainTextResponse from '@/components/PlainTextResponse.vue'

@Component({
  components: {
    TaggableResponse,
    AggregateResponse,
    PlainTextResponse,
    DropdownResponse,
  },
})
export default class ResponseList extends Vue {

  @Prop() private questions!: IQuestion[]
  @Prop() private tags!: ITag[]
  @Prop() private numOfRespondents!: number

  answersExist(question: IQuestion) {
    if (question.answers) {
      return (question.answers.length > 0) ? true : false
    }
    return false
  }

  moreThanOneQuestion(question: IQuestion) {
    if (question.answers) {
      return (question.answers.length > 1) ? true : false
    }
    return false
  }

  isQuestionTaggableResponse(question: IQuestion) {
    const questionType = question.questionType
    return (questionType === 'textarea' || questionType === 'textfield') ? true : false
  }


  @Emit('saveTagUpdate')
  // tslint:disable-next-line
  saveTagUpdate() {}

  @Emit('removeTagHighlight')
  // tslint:disable-next-line
  removeTagHighlight() {}

  @Emit('addTag')
  // tslint:disable-next-line
  addTag() {}

  @Emit('createNewTagAndHighlight')
  // tslint:disable-next-line
  createNewTagAndHighlight() {}

  @Emit('archiveAnswer')
  // tslint:disable-next-line
  archiveAnswer() {}

}
