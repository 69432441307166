









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Avatar extends Vue {

  @Prop() private image!: string
}
