












































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IAnswer } from '@/interfaces'
import Avatar from '@/components/Avatar.vue'
import ResponseMenu from '@/components/ResponseMenu.vue'
import uniqBy from 'lodash/uniqBy'

@Component({
  components: {
    Avatar,
    ResponseMenu,
  },
})
export default class AggregateResponse extends Vue {

  menuVisible: string = ''

  @Prop() private answers!: IAnswer[]

  get choices() {
    if (this.answers) {
      const uniqueUsers: IAnswer[] = uniqBy(this.answers, (r) => {
        return r.text
      })
      return uniqueUsers
    }
  }

  get numOfChoices() {
    if (this.choices) {
      return this.choices.length
    }
    return 0
  }

  get numOfAnswers() {
    if (this.answers) {
      return this.answers.length
    }
  }

  findAnswer(userId: string) {
    return this.answers.filter((a) => {
      return a.text === userId
    })
  }

  getNameForChoice(userId: string) {
    if (this.answers) {
      const choiceAnswers = this.findAnswer(userId)
      if (choiceAnswers.length > 0) {
        if (choiceAnswers.length === 1) {
          return choiceAnswers[0].user.firstName + ' ' + choiceAnswers[0].user.lastName
        }
        if (choiceAnswers.length === 2) {
          return choiceAnswers[0].user.firstName + ' + 1 other respondent'
        }
        return choiceAnswers[0].user.firstName + ' + ' + ( choiceAnswers.length - 1 )  + ' other respondents'
      }
    }
  }

  getAnswersForChoice(choice: IAnswer) {
    return this.answers.filter((c) => {
      return choice.text === c.text
    })
  }

  getValueForChoice(userId: string) {
    const answers: IAnswer[] = this.findAnswer(userId)
    return answers.length
  }

  getPercentage(choiceId: string) {
    if (this.numOfAnswers) {
      const value = this.getValueForChoice(choiceId)
      return Math.round((value / this.numOfAnswers) * 100)
    }
  }

  toggleMenuVisible(choiceId: string) {
    this.menuVisible = choiceId
  }

  @Emit('archiveModalAnswer')
  // tslint:disable-next-line
  archiveModalAnswer() {}
}
