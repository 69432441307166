





























































































import { Vue, Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { Action, Getter } from 'vuex-class'
import { IPollState } from '@/store/modules/polls'
import { IPoll, STATUSES, IUser, IAnswer, INotification, IOutputIntegration, IFrequency } from '@/interfaces'
import { ISlackUser, ISlackChannel } from '../store/modules/slack'
import { GetPollWithQuestions } from '@/queries/getPollQueries'
import AuthorizedPage from '@/mixins/AuthorizedPage'
import TopNav from '@/components/TopNav.vue'
import PollHeroBanner from '@/components/PollHeroBanner.vue'
import PollNav from '@/components/PollNav.vue'
import UpdatePollModal from '@/components/UpdatePollModal.vue'
import SendFormModal from '@/components/SendFormModal.vue'
import SendNotificationModal from '@/components/SendNotificationModal.vue'
import SettingsModal from '@/components/SettingsModal.vue'
import uniqBy from 'lodash/uniqBy'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

const slackNamespace: string = 'slack'
const pollNamespace: string = 'polls'

interface ISendFormPayload {
  newRespondents: IUser[]
  removedRespondents: IUser[]
  publicPoll: boolean
  sendNotification: boolean
  sendMessageNotificationChannel: string
}

interface ISettingsPayload {
  publicPoll?: boolean
  recurringPoll?: boolean
  newRespondents?: IUser[]
  removedRespondents?: IUser[]
  newAdministrators?: IUser[]
  removedAdministrators?: IUser[]
  frequency?: IFrequency
  removedSlackChannels?: ISlackChannel[]
  newSlackChannels?: ISlackChannel[]
  slackNotificationOn?: boolean
  slackNotification?: IOutputIntegration
}

@Component({
  components: {
    TopNav,
    PollHeroBanner,
    PollNav,
    UpdatePollModal,
    SendFormModal,
    SettingsModal,
    SendNotificationModal,
  },
})
export default class PollView extends mixins(AuthorizedPage) {
  @Getter('poll', { namespace: pollNamespace }) public poll!: IPoll
  @Getter('users', { namespace: slackNamespace }) public slackUsers!: ISlackUser[]
  @Getter('channels', { namespace: slackNamespace }) public channels!: ISlackChannel[]

  isFullPage: boolean = true
  isLoading: boolean = true

  isUpdatePollModalActive: boolean = false
  isSendFormModalActive: boolean = false
  isNotificationModalActive: boolean = false
  isSettingsModalActive: boolean = false
  notificationsSent: boolean = false
  sendToAll: boolean = true

  isActiveProxy: any = ''

  settingsTab: string = 'permissions'

  membersFromChannel: ISlackUser[] = []
  administratorsFromChannel: ISlackChannel[] = []

  modalCloseOptions = ['escape', 'x', 'outside']

  get pollId() {
    if (this.poll) {
      return this.poll.id
    }
  }

  get isActive() {
    if (this.poll) {
      if (this.isActiveProxy === '') {
        return (this.poll.status === 'active') ? true : false
      }
      return this.isActiveProxy
    }
  }

  set isActive(value) {
    this.isActiveProxy = value
  }

  get questions() {
    if (this.poll && this.poll.questions) {
      return this.poll.questions.filter((q) => {
        return q.status === STATUSES.active
      })
    }
  }

  get questionsExist() {
    return (this.questions && this.questions.length > 0) ? true : false
  }

  get toggleLabel() {
    return (this.questionsExist) ? 'Turn on/off' : 'Cannot make a form with no questions active'
  }

  get users() {
    if (this.slackUsers) {
      return this.slackUsers.map((u) => {
        return {
          firstName: u.name,
          avatar: u.avatar,
          email: u.email,
        }
      })
    }
  }

  async mounted() {
    await this.getPoll()
    await this.$store.dispatch('slack/getUsers')
    await this.$store.dispatch('slack/getChannels')
    this.isLoading = false

    // Did we just come back from an auth flow that grants us
    // access to public AND private channels? If so open the
    // modal that we were previously on. OR did we just create
    // a new poll?
    if (this.$route.params.modal) {

      if (this.$route.params.modal === 'updatePoll') {
        this.openUpdatePollModal()
      }

      if (this.$route.params.modal === 'settings') {
        const tab = (this.$route.params.tab) ? this.$route.params.tab : 'permissions'
        this.openSettingsModalWithTab(tab)
      }

      if (this.$route.params.modal === 'sendForm') {
        this.openSendFormModal()
      }
    }
  }

  toggleStatus() {
    const status = (this.isActive) ? STATUSES.active : STATUSES.inactive
    if (this.isActive) {
      this.isSendFormModalActive = true
    } else {
      this.updatePoll(this.$route.params.pollId, status)
    }
  }

  openSettingsModalWithTab(tab: string) {
    this.settingsTab = tab
    this.$router.replace({
      name: this.$route.name,
      params: {
        pollId: this.$route.params.pollId,
        modal: 'settings',
        tab,
    }})
    this.openSettingsModal()
  }

  openSettingsModal() {
    this.$router.replace({
      name: this.$route.name,
      params: {
        pollId: this.$route.params.pollId,
        modal: 'settings',
        tab: 'permissions',
      }})
    this.isSettingsModalActive = true
  }

  openUpdatePollModal() {
    this.$router.replace({
      name: this.$route.name,
      params: {
        pollId: this.$route.params.pollId,
        modal: 'updatePoll',
      }})
    this.isUpdatePollModalActive = true
  }

  closeUpdatePollModal() {
    this.$router.replace({
      name: this.$route.name,
      params: {
        pollId: this.$route.params.pollId,
      }})
    this.isUpdatePollModalActive = false
  }

  openSendFormModal() {
    this.$router.replace({
      name: this.$route.name,
      params: {
        pollId: this.$route.params.pollId,
        modal: 'sendForm',
      }})
    this.isSendFormModalActive = true
  }

  closeSendFormModal() {
    this.$router.replace({
      name: this.$route.name,
      params: {
        pollId: this.$route.params.pollId,
      }})
    this.isSendFormModalActive = false
  }

  closeSettingsModal() {
    this.$router.replace({
      name: this.$route.name,
      params: {
        pollId: this.$route.params.pollId,
      }})
    this.isSettingsModalActive = false
  }

  sendTest() {
    this.$store.dispatch('polls/sendNotificationToRespondents', {
      input: {
        pk: this.poll.id,
        sendToAll: false,
        isTest: true,
      },
    })

    this.$buefy.toast.open({
      type: 'is-success',
      message: 'Test sent to your Slack workspace!',
    })
  }

  closeNotificationModal() {
    this.isNotificationModalActive = false
  }

  onActivatePoll() {
    const status = (this.isActive) ? STATUSES.active : STATUSES.inactive
    this.isSendFormModalActive = false
    this.updatePoll(this.$route.params.pollId, status)
  }

  confirmSendNotification(sendToAll: boolean) {
    this.sendToAll = sendToAll
    this.isNotificationModalActive = true
  }

  async getMembersForChannel(payload: ISlackChannel) {
    const members = await this.$store.dispatch('slack/getMembersForChannel', payload.id)
    this.membersFromChannel = members.map((u: ISlackUser) => {
      return {
        firstName: u.name,
        avatar: u.avatar,
        email: u.email,
      }
    })
  }

  async getAdministratorsForChannel(payload: ISlackChannel) {
    const members = await this.$store.dispatch('slack/getMembersForChannel', payload.id)
    this.administratorsFromChannel = members.map((u: ISlackUser) => {
      return {
        firstName: u.name,
        avatar: u.avatar,
        email: u.email,
      }
    })
  }

  async copyPoll(pk: string) {
    const id = await this.$store.dispatch('polls/copyPoll', {
      pk,
    })

    // We need to update the url and then refresh the page to get the new poll
    this.$router.push({ name: 'questions', params: { pollId: id, modal: 'updatePoll' }})
    this.$router.go(0)

  }

  async leavePoll(pk: string) {
    await this.$store.dispatch('polls/removeOwnerFromPoll', {
      input: {
        email: this.user.email,
        pk,
      },
    })

    this.$router.push({ name: 'forms' })

    this.$buefy.toast.open({
      type: 'is-success',
      message: 'You have been removed from this form!',
    })
  }

  async sendPollNotificationMessage(channelId: string) {
    await this.$store.dispatch('polls/sendPollNotificationMessage', {
      input: {
        pk: this.poll.id,
        channelId,
      },
    })
  }

  async sendNotificationToRespondents(sendToAll: boolean) {
    Vue.prototype.$ma.trackEvent({
      action: 'Sent notification to respondents',
      properties: {
        sentToAll: sendToAll,
      },
    })
    this.isNotificationModalActive = false
    await this.$store.dispatch('polls/sendNotificationToRespondents', {
      input: {
        pk: this.poll.id,
        sendToAll,
      },
    })
    this.notificationsSent = true
    this.$buefy.toast.open({
      message: 'Notifications sent!',
      type: 'is-success',
    })
  }

  async getPoll() {
    await this.$store.dispatch('polls/getPoll', {
      pk: this.$route.params.pollId,
      queryType: GetPollWithQuestions,
    })
  }

  updatePollFromNav(status: string) {
    this.updatePoll(this.$route.params.pollId, status)

    let message = ''
    if (status === STATUSES.archived) {
      message = 'Form has been archived'
    } else {
      message = (status === STATUSES.active) ? 'Form is active!' : 'Form is inactive!'
    }

    this.$buefy.toast.open({
      message,
      type: 'is-success',
    })
  }

  async updatePoll(pollId: string, status?: string, publicPoll?: boolean, recurringPoll?: boolean) {
    await this.$store.dispatch('polls/updatePoll', {
      pk: pollId,
      poll: {
        status,
        public: publicPoll,
        recurring: recurringPoll,
      },
    })

    await this.getPoll()

    if (status === STATUSES.archived) {
      this.$router.push({ name: 'forms'})
    }
  }

  async savePollTitleAndDescription(updatedPoll: any) {

    this.isUpdatePollModalActive = false

    await this.$store.dispatch('polls/updatePoll', {
      pk: this.$route.params.pollId,
      poll: {
        title: updatedPoll.title,
        description: updatedPoll.description,
      },
    })

    await this.getPoll()

    this.$buefy.toast.open({
      message: 'Your form has been saved!',
      type: 'is-success',
    })
  }

  async sendForm(payload: ISendFormPayload ) {

    if (payload.newRespondents.length > 0) {
      await this.saveNewRespondents(payload.newRespondents)
    }
    if (payload.removedRespondents.length > 0) {
      await this.saveRemoveRespondents(payload.removedRespondents)
    }
    if (payload.publicPoll !== this.poll.public) {
      this.updatePoll(this.$route.params.pollId, STATUSES.active, payload.publicPoll)
    }
    this.closeSendFormModal()
    this.sendNotificationToRespondents(true)

    if (payload.sendMessageNotificationChannel !== '') {
      this.sendPollNotificationMessage(payload.sendMessageNotificationChannel)
    }
    await this.getPoll()
  }

  async saveRemoveRespondents(removedRespondents: IUser[]) {
    const createdPromises: any = []
    removedRespondents.map((u) => {
      createdPromises.push(this.$store.dispatch('polls/removeRespondentFromPoll', {
        input: {
          email: u.email,
          pk: this.poll.id,
        },
      }))
    })
    await Promise.all(createdPromises)
  }

  async saveNewRespondents(newRespondents: IUser[]) {
    const createdPromises: any = []
    newRespondents.map((respondent) => {
      createdPromises.push(this.$store.dispatch('polls/addRespondentToPoll', {
        input: {
          pk: this.poll.id,
          email: respondent.email,
        },
      }))
    })
    await Promise.all(createdPromises)
  }

  async saveSettings(payload: ISettingsPayload) {

    if (payload.newRespondents) {
      await this.saveNewRespondents(payload.newRespondents)
    }

    if (payload.removedRespondents) {
      await this.saveRemoveRespondents(payload.removedRespondents)
    }

    if (payload.newAdministrators) {
      await this.saveNewAdministrators(payload.newAdministrators)
    }

    if (payload.removedAdministrators) {
      await this.saveRemoveAdministrators(payload.removedAdministrators)
    }

    if ('publicPoll' in payload) {
      await this.updatePoll(this.$route.params.pollId, undefined, payload.publicPoll)
    }

    if (payload.recurringPoll !== undefined) {
      await this.updatePoll(this.$route.params.pollId, undefined, undefined, payload.recurringPoll)
    }

    if (payload.frequency !== undefined) {
      await this.saveFrequency(this.poll.recurring, payload.frequency)
    }

    if (payload.removedSlackChannels) {
      await this.saveRemoveSlackChannel(payload.removedSlackChannels)
    }

    if (payload.newSlackChannels) {
      await this.saveNewSlackChannel(payload.newSlackChannels)
    }

    if ('slackNotificationOn' in payload) {
      let isNotificationOn: boolean = false
      if (this.poll.notifications) {
        isNotificationOn = (this.poll.notifications.length > 0) ? true : false
      }
      if (payload.slackNotificationOn !== isNotificationOn) {
        await this.saveSlackNotification()
      }
    }

    this.isSettingsModalActive = false

    this.$buefy.toast.open({
      type: 'is-success',
      message: 'Settings have been saved!',
    })

    this.getPoll()
  }

  async saveNewAdministrators(newAdministrators: IUser[]) {
    const createdPromises: any = []
    if (newAdministrators) {
      newAdministrators.map((owner) => {
        if (owner) {
          createdPromises.push(this.$store.dispatch('polls/addOwnerToPoll', {
            input: {
              pk: this.poll.id,
              email: owner.email,
            },
          }))
        }
      })
      await Promise.all(createdPromises)
    }
  }

  async saveRemoveAdministrators(removedAdministrators: IUser[]) {
    const createdPromises: any = []

    removedAdministrators.map((u) => {
      if (this.poll.members) {

        const user = this.poll.members.find((m) => {
          return m.email === u.email
        })

        if (user) {
          createdPromises.push(this.$store.dispatch('polls/removeOwnerFromPoll', {
            input: {
              email: user.email,
              pk: this.poll.id,
            },
          }))
        }
      }
    })
    await Promise.all(createdPromises)
  }

  async saveFrequency(recurringPoll: boolean, frequency: IFrequency) {

    if (!recurringPoll) {
      if (this.poll.frequency) {
        await this.$store.dispatch('polls/deletePollFrequency', {
          pk: this.poll.id,
        })
      }
    } else {
      if (this.poll.frequency) {
        await this.$store.dispatch('polls/updatePollFrequency', {
          pk: this.poll.frequency.id,
          input: omit(frequency, ['id', '__typename']),
        })
      } else {
        await this.$store.dispatch('polls/createPollFrequency', {
          pk: this.poll.id,
          input: omit(frequency, ['id', '__typename']),
        })
      }
    }
  }

  async saveRemoveSlackChannel(removeChannelList: ISlackChannel[]) {
    if (this.poll.outputIntegrations) {

      const createdPromises: any = []
      removeChannelList.map((c) => {
        if (this.poll.outputIntegrations) {
          const integration = this.poll.outputIntegrations.find((i) => {
              return i.outputType === 'slack' && i.name === c.name
          })
          if (integration) {
            createdPromises.push(this.$store.dispatch('polls/deleteOutputIntegration', {
              pk: integration.id,
            }))
          }
        }
      })
      await Promise.all(createdPromises)
    }
  }

  async saveNewSlackChannel(newChannelList: ISlackChannel[]) {

    const createdPromises: any = []

    newChannelList.map((integration: ISlackChannel) => {
      createdPromises.push(this.$store.dispatch('polls/createOutputIntegration', {
        pk: this.poll.id,
        integration: {
          outputType: 'slack',
          name: integration.name,
          config: {
            id: integration.id,
            name: integration.name,
          },
        },
      }))
    })
    await Promise.all(createdPromises)

  }

  async saveSlackNotification() {
    if (this.poll.notifications) {
      if (this.poll.notifications.length === 0) {
        await this.$store.dispatch('polls/createSlackNotifcation', {
          pk: this.poll.id,
          notification: {
            type: 'slack_dm',
          },
        })
      } else {
        const notification = this.findSlackNotification()
        if (notification) {
          await this.$store.dispatch('polls/deleteSlackNotification', {
            pk: notification.id,
          })
        }
      }
    }
  }

  findSlackNotification() {
    if (this.poll.notifications) {
      return this.poll.notifications.find((n) => {
          return n.type === 'slack_dm' && n.user.email === this.user.email
      })
    }
  }
}
