import gql from 'graphql-tag'

export const GetPollWithQuestions =  gql`
  query ($pk: UUID!) {
    poll(pk: $pk) {
      id
      title
      description
      createdAt
      status
      public
      recurring
      tags {
        id
        text
        color
      }
      owner {
        avatar
        email
        firstName
        lastName
      }
      members {
        avatar
        email
        firstName
        lastName
      }
      questions {
        text
        questionType
        id
        status
        index
        createdAt
        choices {
          id
          value
          index
          status
        }
        answers {
          id
          createdAt
          text
          displayText
          status
          user {
            avatar
            email
            firstName
            lastName
          }
          taghighlightSet {
            id
            content
            startChar
            endChar
            tag {
              id
              text
              color
            }
          }
        }
      }
      respondents {
        email
        avatar
        firstName
        lastName
      }
      notifications {
        id
        type
        user {
          email
          firstName
          avatar
          lastName
        }
      }
      frequency {
        id
        interval
        dayOfMonth
        includeWeekend,
        time,
        weekday,
      }
      outputIntegrations {
        id
        outputType
        name
        config
      }
    }
  }`
