






































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { Action, Getter } from 'vuex-class'
import { IPollState } from '@/store/modules/polls'
import { IPoll, IUser, STATUSES, ITemplate, FIELDS } from '@/interfaces'
import AuthorizedPage from '@/mixins/AuthorizedPage'
import TopNav from '@/components/TopNav.vue'
import Banner from '@/components/Banner.vue'
import PollList from '@/components/PollList.vue'
import PollFilters from '@/components/PollFilters.vue'
import NoPollsState from '@/components/NoPollsState.vue'
import NoPollsFound from '@/components/NoPollsFound.vue'
import CreatePollModal from '@/components/CreatePollModal.vue'
import orderBy from 'lodash/orderBy'

const namespace: string = 'polls'
const profileNamespace: string = 'profile'
const templateNamespace: string = 'templates'

interface IFilterPayload {
  status: string,
  user: string
}

@Component({
  components: {
    TopNav,
    Banner,
    PollList,
    PollFilters,
    NoPollsState,
    NoPollsFound,
    CreatePollModal,
  },
})
export default class PollsView extends mixins(AuthorizedPage) {
  @Getter('polls', { namespace }) public polls!: IPoll[]
  @Getter('poll', { namespace }) public poll!: IPoll
  @Getter('team', { namespace: profileNamespace }) public team!: IUser[]
  @Getter('templates', { namespace: templateNamespace }) public templates!: ITemplate[]

  isFullPage: boolean = true
  isLoading: boolean = true
  isCreatePollModalActive: boolean = false

  filters: IFilterPayload = {
    status: 'all',
    user: 'all',
  }
  clearFiltersCount: number = 0

  async mounted() {
    await this.$store.dispatch('polls/getPolls')
    await this.$store.dispatch('profile/getAllUsers')
    await this.$store.dispatch('templates/getTemplates')
    this.isLoading = false
  }

  get filteredPolls() {
    let polls: IPoll[] = []
    if (this.polls) {
      if (this.filters.status !== 'all') {
        polls = this.polls.filter((p: IPoll) => {
          if (this.filters.status === STATUSES.archived) {
            return p.status === this.filters.status
          } else {
            return p.status !== STATUSES.archived
          }
        })
      } else {
        polls = this.polls.filter((p: IPoll) => {
          return p.status !== STATUSES.archived
        })
      }
      if (this.filters.user !== 'all') {
        polls = polls.filter((p: IPoll) => {
          if (p.owner) {
            return p.owner.email === this.filters.user
          }
        })
      }
    }
    return orderBy(polls, ['createdAt'])
  }

  get pollsExist() {
    if (this.polls) {
      return (this.polls.length > 0 ) ? true : false
    }
    return false
  }

  get filteredPollsExist() {
    if (this.filteredPolls) {
      return (this.filteredPolls.length === 0) ? false : true
    }
  }

  get filtersDisabled() {
    if (this.pollsExist) {
      return false
    }
    return true
  }

  updateFilters(payload: IFilterPayload) {
    this.filters = payload
  }

  clearFilters() {
    this.filters = {
      status: 'all',
      user: 'all',
    }
    this.clearFiltersCount++
  }

  closeModal() {
    this.isCreatePollModalActive = false
  }

  createPollClicked() {
    this.isCreatePollModalActive = true

    Vue.prototype.$ma.trackEvent({
      action: 'Clicked create new form',
    })
  }

  async createPoll(templateId: string) {

    Vue.prototype.$ma.trackEvent({
      action: 'Created poll',
    })

    let result: any = ''

    if (templateId === '') {
      result = await this.$store.dispatch('polls/createPoll', {
        poll: {
          title: 'New Untitled Form',
          description: '',
          status: 'inactive',
          public: false,
          recurring: false,
          questions: [
            {
              text: '',
              questionType: FIELDS.textarea,
              index: '0',
            },
          ],
        },
      })
    } else {
        result = await this.$store.dispatch('templates/createPollFromTemplate', {
          pk: templateId,
      })
    }

    this.$router.push({ name: 'questions', params: { pollId: result.id , modal: 'updatePoll' }})
  }

  async updatePoll(payload: IPoll) {

    await this.$store.dispatch('polls/updatePoll', {
      pk: payload.id,
      poll: {
        status: payload.status,
      },
    })

    await this.$store.dispatch('polls/getPolls')

    const message = (payload.status === STATUSES.archived) ?
                    'Form has been archived!' : 'Form status has been updated!'
    this.$buefy.toast.open({
      type: 'is-success',
      message,
    })

  }

  async copyPoll(pk: string) {
    const id = await this.$store.dispatch('polls/copyPoll', {
      pk,
    })
    this.$router.push({ name: 'questions', params: { pollId: id, modal: 'updatePoll' }})
    this.$buefy.toast.open({
      type: 'is-success',
      message: 'Welcome to your new form!',
    })
  }

  async leavePoll(pk: string) {
    await this.$store.dispatch('polls/removeOwnerFromPoll', {
      input: {
        email: this.user.email,
        pk,
      },
    })

    this.$store.dispatch('polls/getPolls')
    this.$buefy.toast.open({
      type: 'is-success',
      message: 'You have been removed from this form!',
    })
  }
}
