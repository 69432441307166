




















































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IPoll, IUser, STATUSES } from '@/interfaces'
import range from 'lodash/range'
import moment from 'moment-timezone'

// overrides moment.fn.zoneName like suggested in docs here:
// https://momentjs.com/timezone/docs/#/using-timezones/formatting/
const abbrs: any = {
  EST : 'Eastern Standard Time',
  EDT : 'Eastern Daylight Time',
  CST : 'Central Standard Time',
  CDT : 'Central Daylight Time',
  MST : 'Mountain Standard Time',
  MDT : 'Mountain Daylight Time',
  PST : 'Pacific Standard Time',
  PDT : 'Pacific Daylight Time',
}

moment.fn.zoneName = function() {
  const abbr = this.zoneAbbr()
  return abbrs[abbr] || abbr
}

@Component
export default class PollFrequency extends Vue {

  defaultFrequency = {
    interval: 'daily',
    dayOfMonth: 1,
    includeWeekend: false,
    time: '16:00:00.000000',
    weekday: 'monday',
  }

  intervals: string[] = [
    'daily',
    'weekly',
    'monthly',
  ]
  intervalProxy = ''

  days: string[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]
  dayOfWeekProxy: string = ''
  includeWeekendProxy: boolean = false
  weekendText: string = 'Include Sat & Sun'

  daysOfTheMonth = [
    { id: 1, display: '1st' },
    { id: 2, display: '2nd' },
    { id: 3, display: '3rd' },
    { id: 4, display: '4th' },
    { id: 5, display: '5th' },
    { id: 6, display: '6th' },
    { id: 7, display: '7th' },
    { id: 8, display: '8th' },
    { id: 9, display: '9th' },
    { id: 10, display: '10th' },
    { id: 11, display: '11th' },
    { id: 12, display: '12th' },
    { id: 13, display: '13th' },
    { id: 14, display: '14th' },
    { id: 15, display: '15th' },
    { id: 16, display: '16th' },
    { id: 17, display: '17th' },
    { id: 18, display: '18th' },
    { id: 19, display: '19th' },
    { id: 20, display: '20th' },
    { id: 21, display: '21st' },
    { id: 22, display: '22nd' },
    { id: 23, display: '23rd' },
    { id: 24, display: '24th' },
    { id: 25, display: '25th' },
    { id: 26, display: '26th' },
    { id: 27, display: '27th' },
    { id: 28, display: '28th' },
    { id: 29, display: '29th' },
    { id: 30, display: '30th' },
    { id: 31, display: '31st' },
  ]
  dayOfMonthProxy: number = 0

  hoursOfDay: number[] = range(1, 13)
  hourOfDayProxy: number = 0

  periods: string[] = [
    'am',
    'pm',
  ]
  periodProxy: string = ''

  PageFirstRendered: boolean = true

  @Prop() private poll!: IPoll
  @Prop() private user!: IUser

  get frequency() {
    if (this.poll && this.poll.frequency) {
      return this.poll.frequency
    }
    return this.defaultFrequency
  }

  get interval() {
    return (this.intervalProxy === '') ? this.frequency.interval : this.intervalProxy
  }

  set interval(value: string) {
    this.intervalProxy = value
  }

  get includeWeekend() {
    return (this.PageFirstRendered) ? this.frequency.includeWeekend : this.includeWeekendProxy
  }

  set includeWeekend(value: boolean) {
    this.includeWeekendProxy = value
  }

  get dayOfWeek() {
    return (this.dayOfWeekProxy === '') ? this.frequency.weekday : this.dayOfWeekProxy
  }

  set dayOfWeek(value: string) {
    this.dayOfWeekProxy = value
  }

  get dayOfMonth() {
    return (this.dayOfMonthProxy === 0) ? this.frequency.dayOfMonth : this.dayOfMonthProxy
  }

  set dayOfMonth(value: number) {
    this.dayOfMonthProxy = value
  }

  get hourOfDay() {
    let time: number = 9
    if (this.hourOfDayProxy === 0 ) {
      if (this.frequency && this.user.timezone) {
        time = parseInt(moment.utc(this.frequency.time, 'HH:mm:ss.SSSSSS').tz(this.user.timezone).format('H'), 10)
      }
    } else {
      time = this.hourOfDayProxy
    }
    if (time < 12) {
      return time
    } else {
      return time - 12
    }
  }

  set hourOfDay(value: number) {
    this.hourOfDayProxy = value
  }

  get period() {
    let time: number = 0
    if (this.periodProxy === '') {
      if (this.frequency && this.user.timezone) {
        time = parseInt(moment.utc(this.frequency.time, 'HH:mm:ss.SSSSSS').tz(this.user.timezone).format('H'), 10)
      }
      return (time < 12) ? 'am' : 'pm'
    } else {
      return this.periodProxy
    }
  }

  set period(value: string) {
    this.periodProxy = value
  }

  get timezoneLabel() {
    if (this.user && this.user.timezone) {
      return moment.tz(this.user.timezone).format('zz')
    }
  }

  toggleIncludeWeekend() {
    this.PageFirstRendered = false
    this.frequencyUpdated()
  }

  @Emit('frequencyUpdated')
  frequencyUpdated() {
    const hourOfDay = (this.period === 'am') ? this.hourOfDay : this.hourOfDay + 12
    return {
      interval: this.interval,
      dayOfMonth: this.dayOfMonth,
      includeWeekend: this.includeWeekend,
      time: moment({hour: hourOfDay}).utc().format('HH:mm:ss.SSSSSS'),
      weekday: this.dayOfWeek,
    }
  }

}
