





















































































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
import { STATUSES, IUser, IQuestion, IResponseFilters, ITag } from '@/interfaces'
import QuestionFilter from '@/components/QuestionFilter.vue'
import UserFilter from '@/components/UserFilter.vue'
import TagFilter from '@/components/TagFilter.vue'
import moment from 'moment'

@Component({
  components: {
    QuestionFilter,
    UserFilter,
    TagFilter,
  },
})
export default class ResponseFilters extends Vue {

  selectedQuestion: string = ''
  selectedRespondent: string = 'all'

  filters: IResponseFilters = {
    question: 'all',
    respondent: 'all',
    tag: 'all',
    startDate: 'all',
    endDate: 'all',
  }

  startDate: Date | null = null
  endDate: Date | null = null

  @Prop() private respondents!: IUser[]
  @Prop() private questions!: IQuestion[]
  @Prop() private tags!: ITag[]
  @Prop() private clearFilters!: number

  @Watch('clearFilters')
   onClearFiltersUpdate() {
     this.resetFilters()
  }

  get filteredQuestions() {
    if (this.questions) {
      return this.questions.filter((option) => {
          return option.text
              .toString()
              .toLowerCase()
              .indexOf(this.selectedQuestion.toLowerCase()) >= 0
      })
    }
  }

  get questionTag() {
    if (this.filters.question !== 'all') {
      const foundQ = this.questions.find((q) => {
        return q.id === this.filters.question
      })
      if (foundQ) {
        return foundQ.text
      }
    }
    return ''
  }

  get userTag() {
    if (this.filters.respondent !== 'all') {
      const foundU = this.respondents.find((r) => {
        return r.email === this.filters.respondent
      })
      if (foundU) {
        return foundU.firstName
      }
    }
    return ''
  }

  get tagTag() {
    if (this.filters.tag !== 'all') {
      const foundT = this.tags.find((t) => {
        return t.id === this.filters.tag
      })
      if (foundT) {
        return foundT.text
      }
    }
    return ''
  }

  formatStartDate() {
    if (this.startDate) {
      return moment(this.startDate).format('MMMM D YYYY')
    }
  }

  formatEndDate() {
    if (this.endDate) {
      return moment(this.endDate).format('MMMM D YYYY')
    }
  }

  questionSelected(question: IQuestion) {
    if (question) {
      this.filters.question = question.id
      this.filter()
    }
  }

  userSelected(user: IUser) {
    this.filters.respondent = user.email
    this.filter()
  }

  tagSelected(tag: ITag) {
    this.filters.tag = tag.id
    this.filter()
  }

  startDateSelected(date: string) {
    if (date) {
      this.filters.startDate = date
      this.filter()
    }
  }

  endDateSelected(date: string) {
    if (date) {
      this.filters.endDate = date
      this.filter()
    }
  }

  resetQuestionFilter() {
    this.filters.question = 'all'
    this.filter()
  }

  resetUserFilter() {
    this.filters.respondent = 'all'
    this.filter()
  }

  resetTagFilter() {
    this.filters.tag = 'all'
    this.filter()
  }

  resetFilters() {
    this.filters = {
      question: 'all',
      respondent: 'all',
      tag: 'all',
      startDate: 'all',
      endDate: 'all',
    }
    this.startDate = null
    this.endDate = null
    this.filter()
  }

  @Emit('filter')
  filter() {
    return this.filters
  }
}
