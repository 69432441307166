export interface IUser {
  id?: string
  teamId?: string
  timezone?: string
  slackIntegration?: string
  hasPrivateToken?: string
  email: string
  avatar: string
  firstName: string
  lastName: string
}

export interface IPoll {
  id?: string
  title: string
  createdAt?: string
  description: string
  public: boolean
  recurring: boolean
  owner: IUser
  status: string
  questions?: IQuestion[]
  outputIntegrations?: IOutputIntegration[]
  notifications?: INotification[]
  members?: IUser[]
  respondents?: IUser[]
  tags?: ITag[]
  frequency?: IFrequency
}

export interface ITemplate {
  id?: string
  title: string
  description: string
  recurring: boolean
}

export interface ITag {
  id: string
  text: string
  color: string
  taghighlightSet?: ITagHighlight[]
}

export interface ITagHighlight {
  id: string
  content: string
  startChar: number
  endChar: number
  answer: IAnswer
  tag: ITag
}

export interface IQuestion {
  id: string
  questionType: string
  text: string
  index: number
  status: string
  createdAt?: string
  choices: IChoice[]
  answers?: IAnswer[]
}

export interface IChoice {
  id?: string
  value: string
  index: number
  status: string
}

export interface IOutputIntegration {
  id: string
  outputType: string
  config: string
  name: string
}

export interface INotification {
  id: string
  type: string
  user: IUser
}

export interface IFrequency {
  id?: string
  dayOfMonth: number
  includeWeekend: boolean
  interval: string
  time: string
  weekday: string
}

export interface IAnswer {
  id: string
  text: string
  createdAt: string
  status: string
  user: IUser
  displayText: string
  taghighlightSet?: ITagHighlight[]
}

export interface IResponseFilters {
  question: string
  respondent: string
  tag: string
  startDate: string
  endDate: string
}

export enum STATUSES {
  active = 'active',
  inactive = 'inactive',
  archived = 'archived',
}

export enum FIELDS {
  textfield = 'textfield',
  textarea = 'textarea',
  dropdown = 'dropdown',
  user = 'user',
  channel = 'channel',
}

export const tagColors = [
  '#FFE384', // yellow
  '#D4E8FF', // blue
  '#D9FBB4', // green
  '#FFD9DE', // red
  '#DACCFF', // purple
]
