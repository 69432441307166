












































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { IQuestion, IChoice, FIELDS, STATUSES } from '@/interfaces'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo)

@Component
export default class QuestionForm extends Vue {

  fields = [
    {
      string: 'Single line',
      value: FIELDS.textfield,
      helpText: 'User can respond using a single line text field',
    },
    {
      string: 'Paragraph',
      value: FIELDS.textarea,
      helpText: 'User can respond with a lot of multi-line text',
    },
    {
      string: 'Dropdown',
      value: FIELDS.dropdown,
      helpText: 'User responds by selecting one option from a list',
    },
    {
      string: 'Select Slack User',
      value: FIELDS.user,
      helpText: 'User responds by selecting a Slack user from your workspace',
    },
  ]

  textCharLimit: number = 48
  maxOptions: number = 10
  optionLength: number = 75
  optionCounter: boolean = true

  typeProxy: string = ''
  textProxy: string = ''

  firstMounted: boolean = true

  @Prop() private question!: IQuestion
  @Prop() private focusNewQuestion!: boolean
  @Prop() private index!: number

  get type() {
    return (this.typeProxy === '') ? this.question.questionType : this.typeProxy
  }

  set type(value: string) {
    if (value) {
      this.typeProxy = value
    }
  }

  get text() {
    return (this.textProxy === '' && this.firstMounted) ? this.question.text : this.textProxy
  }

  set text(value: string) {
    if (value !== undefined) {
      this.textProxy = value
    }
  }

  get typeHelpText() {
    const foundField = this.fields.find((f) => {
      return f.value === this.type
    })
    if (foundField) {
      return foundField.helpText
    }
  }

  get choices() {
    return this.question.choices
  }

  get areOptionsMaxed() {
    return (this.choices.length === 10) ? true : false
  }

  mounted() {
    this.doWeNeedToAddAnOption()
    if (this.focusNewQuestion) {
      const context: any = this
      context.$refs.textInput.focus()
      this.$nextTick(() => {
        this.$scrollTo(('#text-' + this.index), 800, { easing: 'linear' })
      })
    }
  }

  onTypeChange() {
    this.doWeNeedToUpdateText()
    this.updateQuestion()
    this.doWeNeedToAddAnOption()
  }

  deleteQuestionPrompt() {
    if (this.question.id.substring(0, 3) !== 'new') {
      const message: string = 'Responses to this question will not be deleted. Would you like to delete this question?'
      this.$buefy.dialog.confirm({
        message,
        type: 'is-danger',
        onConfirm: () => {
          this.deleteQuestion()
        },
      })
    } else {
      this.removeUnsavedQuestion()
    }
  }

  addOption(index: number) {
    if (this.choices.length !== this.maxOptions) {
      this.choices.splice(index + 1, 0, {
        value: '',
        status: STATUSES.active,
        index: 0,
      })
      const ref: string = 'choice' + (index + 1)
      this.$nextTick(() => {
        const context: any = this
        context.$refs[ref][0].focus()
      })
      this.doWeNeedToUpdateText()
      this.updateQuestion()
    }
  }

  deleteOption(index: number, choice: IChoice) {
    if ('id' in choice) {
      this.$set(this.choices[index], 'status', STATUSES.archived)
    } else {
      this.choices.splice(index, 1)
    }
    this.doWeNeedToUpdateText()
    this.doWeNeedToAddAnOption()
    this.updateQuestion()
  }

  doWeNeedToAddAnOption() {
    const filteredChoices = this.choices.filter((c) => {
      return c.status !== STATUSES.archived
    })
    if (this.type === FIELDS.dropdown && filteredChoices.length === 0) {
      this.choices.push({
        value: '',
        status: STATUSES.active,
        index: 0,
      })
    }
  }

  doWeNeedToUpdateText() {
    // prevent text from being cleared out if type is changed when first mounted
    this.textProxy = (this.textProxy === '' && this.firstMounted) ? this.question.text : this.textProxy
  }

  @Emit('removeUnsavedQuestion')
  removeUnsavedQuestion() {
    return this.question
  }

  @Emit('deleteQuestion')
  deleteQuestion() {
    return this.question
  }

  @Emit('updateQuestion')
  updateQuestion() {
    this.firstMounted = false

    return {
      id: this.question.id,
      questionType: this.type,
      text: this.text,
      index: this.index,
      status: STATUSES.active,
      choices: this.choices,
      createdAt: this.question.createdAt,
    }
  }
}
