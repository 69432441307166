


























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class TemplateCard extends Vue {

  @Prop() private template: any

  get title() {
    if (this.template) {
      return this.template.title
    }
  }

  get description() {
    if (this.template) {
      return this.template.description
    }
  }

  get recurring() {
    if (this.template) {
      return this.template.recurring
    }
  }

  @Emit('createPoll')
  createPoll() {
    return this.template.id
  }
}
