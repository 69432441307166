









































































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { IPoll, IUser, IFrequency, IOutputIntegration } from '@/interfaces'
import { ISlackChannel } from '@/store/modules/slack'
import ManageRespondents from '@/components/ManageRespondents.vue'
import ManageAdministrators from '@/components/ManageAdministrators.vue'
import PollFrequency from '@/components/PollFrequency.vue'
import ChannelSearch from '@/components/ChannelSearch.vue'
import ChannelList from '@/components/ChannelList.vue'
import sortBy from 'lodash/sortBy'

@Component({
  components: {
    ManageRespondents,
    ManageAdministrators,
    PollFrequency,
    ChannelSearch,
    ChannelList,
  },
})
export default class SettingsModal extends Vue {

  removedRespondents: IUser[] = []
  newRespondents: IUser[] = []

  removedAdministrators: IUser[] = []
  newAdministrators: IUser[] = []

  publicPollProxy: any = ''

  recurringPollProxy: boolean = false
  firstRenderedForRecurringPoll: boolean = true

  frequencyProxy: IFrequency = {
    interval: 'daily',
    dayOfMonth: 1,
    includeWeekend: false,
    time: '16:00:00.000000',
    weekday: 'monday',
  }
  firstRenderedForFrequency: boolean = true

  slackNotificationOnProxy: any = ''
  slackIntegrationProxy: ISlackChannel[] = []

  newSlackChannels: ISlackChannel[] = []
  removedSlackChannels: ISlackChannel[] = []

  firstRenderedForIntegration: boolean = true

  saveNeeded: boolean = false

  tabs: string[] = [
    'permissions',
    'recurring',
    'notifications',
  ]
  activeTabProxy: number = -1

  @Prop() private poll!: IPoll
  @Prop() private users!: IUser[]
  @Prop() private user!: IUser
  @Prop() private membersFromChannel!: IUser[]
  @Prop() private administratorsFromChannel!: IUser[]
  @Prop() private channels!: ISlackChannel[]
  @Prop() private tab!: string

  get activeTab() {
    if (this.tab && this.activeTabProxy === -1) {
      const index = this.tabs.findIndex((s) => {
        return s === this.tab
      })
      return index
    }
    return this.activeTabProxy
  }

  set activeTab(value: number) {
    this.activeTabProxy = value
  }

  get recurringPoll() {
    if (this.poll) {
      if (this.poll.recurring !== undefined) {
        return (this.firstRenderedForRecurringPoll) ? this.poll.recurring : this.recurringPollProxy
      }
      return false
    }
    return false
  }

  set recurringPoll(value: boolean) {
    this.recurringPollProxy = value
  }

  get frequency() {
    if (this.poll.frequency) {
      return (this.firstRenderedForFrequency) ? this.poll.frequency : this.frequencyProxy
    }
    return this.frequencyProxy
  }

  set frequency(value: IFrequency) {
    this.frequencyProxy = value
  }

  get publicPoll() {
    if (this.poll) {
      if (this.publicPollProxy === '') {
        return this.poll.public
      }
      return this.publicPollProxy
    }
    return false
  }

  set publicPoll(value: boolean) {
    this.publicPollProxy = value
  }

  get respondents() {
    if (this.poll) {
      if (this.poll.respondents) {
        return this.poll.respondents
      }
    }
  }

  get administrators() {
    if (this.poll) {
      if (this.poll.members) {
        return this.poll.members
      }
    }
  }

  get sortedChannels() {
    return (this.channels) ? sortBy(this.channels, ['name']) : []
  }

  get slackIntegrations() {
    if (this.poll) {
      if (this.firstRenderedForIntegration) {
        if (this.poll.outputIntegrations) {
          const integrations = this.poll.outputIntegrations.filter((i) => {
              return i.outputType === 'slack'
          })
          if (integrations) {
            const slackIntegrations = integrations.map((i: IOutputIntegration) => {
              return JSON.parse(i.config)
            })
            return slackIntegrations
          }
        }
      }
      return this.slackIntegrationProxy
    }
    return []
  }

  set slackIntegrations(value) {
    if (value) {
      this.slackIntegrationProxy = value
    }
  }

  get numOfIntegrations() {
    if (this.slackIntegrations) {
      return this.slackIntegrations.length
    }
  }

  get notifications() {
    if (this.poll) {
      return this.poll.notifications
    }
  }

  get slackNotificationOn() {
    if (this.notifications && this.slackNotificationOnProxy === '') {
      const notification = this.notifications.find((n) => {
        return n.type === 'slack_dm' && n.user.email === this.user.email
      })
      return (notification) ? true : false
    }
    return this.slackNotificationOnProxy
  }

  set slackNotificationOn(value) {
    if (value) {
      this.slackNotificationOnProxy = value
    }
  }

  toggleRecurringPoll() {
    this.firstRenderedForRecurringPoll = false
    if (this.recurringPoll !== this.poll.recurring) {
      this.saveNeeded = true
    }
  }

  get hasPrivateChannelToken() {
    if (this.user) {
      if (this.user.hasPrivateToken === 'False') {
        return false
      } else {
        return true
      }
    }
    return false
  }

  updatePath() {
    this.$router.replace({
      name: this.$route.name,
      params: {
        pollId: this.$route.params.pollId,
        modal: 'settings',
        tab: this.tabs[this.activeTab],
    }})
  }

  toggleSlackNotification(bool: boolean) {
    this.saveNeeded = true
    this.slackNotificationOnProxy = bool
  }

  addRespondent(user: IUser) {
    this.saveNeeded = true
    this.newRespondents.push(user)
  }

  removeRespondent(user: IUser) {
    if (this.respondents) {
      const userExistsAsRespondent = this.respondents.findIndex((r: IUser) => {
          return r.email === user.email
      })
      if (userExistsAsRespondent !== -1) {
        this.saveNeeded = true
        this.removedRespondents.push(user)
      }
    }

    if (this.newRespondents.length > 0) {
      const userExistsAsUnsavedRespondent = this.newRespondents.findIndex((r: IUser) => {
        return r.email === user.email
      })
      if (userExistsAsUnsavedRespondent !== -1) {
        this.newRespondents.splice(userExistsAsUnsavedRespondent, 1)
      }
    }
  }

  addAdministrator(user: IUser) {
    this.saveNeeded = true
    this.newAdministrators.push(user)
  }

  removeAdministrator(user: IUser) {
    if (this.administrators) {
      const userExistsAsAdministrator = this.administrators.findIndex((r: IUser) => {
          return r.email === user.email
      })
      if (userExistsAsAdministrator !== -1) {
        this.saveNeeded = true
        this.removedAdministrators.push(user)
      }
    }

    if (this.newAdministrators.length > 0) {
      const userExistsAsUnsavedAdministrator = this.newAdministrators.findIndex((r: IUser) => {
        return r.email === user.email
      })
      if (userExistsAsUnsavedAdministrator !== -1) {
        this.newRespondents.splice(userExistsAsUnsavedAdministrator, 1)
      }
    }
  }

  addSlackChannel(channel: ISlackChannel) {
    this.firstRenderedForIntegration = false

    let channelAlreadyExistsAsIntegration = ''
    if (this.slackIntegrations) {
      channelAlreadyExistsAsIntegration = this.slackIntegrations.find((c) => c.id === channel.id )
    }

    if (!channelAlreadyExistsAsIntegration) {
      this.slackIntegrationProxy.push(channel)
      this.newSlackChannels.push(channel)
      this.saveNeeded = true
    }
  }

  removeSlackChannel(channel: ISlackChannel) {
    this.firstRenderedForIntegration = false

    if (this.poll.outputIntegrations) {
      const savedIntegration = this.poll.outputIntegrations.findIndex((i) => {
        return channel.id === i.id
      })
      if (savedIntegration) {
        this.removedSlackChannels.push(channel)
        this.saveNeeded = true
      }
    }

    const index = this.slackIntegrations.findIndex((i) => {
      return i.id === channel.id
    })
    if (index !== -1) {
      this.slackIntegrationProxy.splice(index, 1)
    }

    if (this.newSlackChannels.length > 0) {
      const channelExistsUnsaved = this.newSlackChannels.findIndex((r: ISlackChannel) => {
        return r.id === channel.id
      })
      if (channelExistsUnsaved !== -1) {
        this.newSlackChannels.splice(channelExistsUnsaved, 1)
      }
    }
  }

  updatePublicPoll(value: boolean) {
    this.publicPoll = value
    this.saveNeeded = true
  }

  frequencyUpdated(input: IFrequency) {
    this.firstRenderedForFrequency = false
    this.frequencyProxy = input
    this.saveNeeded = true
  }

  openAuthFlow() {
    window.location.href = '/slack/user/start'
  }

  clickedSave() {
    const payload: {[k: string]: any} = {}

    if (this.newRespondents.length > 0) {
      payload.newRespondents = this.newRespondents
    }

    if (this.removedRespondents.length > 0) {
      payload.removedRespondents = this.removedRespondents
    }

    if (this.newAdministrators.length > 0) {
      payload.newAdministrators = this.newAdministrators
    }

    if (this.removedAdministrators.length > 0) {
      payload.removedAdministrators = this.removedAdministrators
    }

    if (this.poll.public !== this.publicPoll) {
      payload.publicPoll = this.publicPoll
    }

    if (this.poll.recurring !== this.recurringPoll) {
      payload.recurringPoll = this.recurringPoll
    }

    if (this.frequency !== this.poll.frequency) {
      payload.frequency = this.frequency
    }

    if (this.removedSlackChannels.length !== 0) {
      payload.removedSlackChannels = this.removedSlackChannels
    }

    if (this.newSlackChannels.length !== 0) {
      payload.newSlackChannels = this.newSlackChannels
    }

    payload.slackNotificationOn = this.slackNotificationOn

    this.save(payload)
  }

  @Emit('closeModal')
  // tslint:disable-next-line
  closeModal() {}

  @Emit('save')
  save(payload: any) {
    return payload
  }

  @Emit('channelSelectedFromAdministrators')
  // tslint:disable-next-line
  channelSelectedFromAdministrators() {}

  @Emit('channelSelectedFromRespondents')
  // tslint:disable-next-line
  channelSelectedFromRespondents() {}
}
