














import { Component, Vue, Emit } from 'vue-property-decorator'

@Component
export default class NoResultsState extends Vue {

  @Emit('resetFilters')
  // tslint:disable-next-line
  resetFilters() {}
}
