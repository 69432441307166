














































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { IUser } from '@/interfaces'
import { ISlackChannel } from '../store/modules/slack'

interface ISelection {
  avatar: string
  name: string
  id: string
  type: string
}

@Component
export default class UserFilter extends Vue {

  selection: string = ''

  @Prop() private users!: IUser[]
  @Prop() private channels!: ISlackChannel[]
  @Prop() private placeholder!: string
  @Prop() private disabled!: boolean

  get combinedUserAndChannels() {
    if (this.users && this.channels) {
      const updatedUsers = this.users.map((u) => {
        return {
          avatar: u.avatar,
          name: u.firstName,
          id: u.email,
          type: 'user',
        }
      })
      const updatedChannels = this.channels.map((c) => {
        return {
          avatar: '',
          name: c.name,
          id: c.id,
          type: 'channel',
          isPrivate: c.isPrivate,
        }
      })
      return updatedUsers.concat(updatedChannels)
    }
  }

  get filteredUsersAndChannels() {
    if (this.combinedUserAndChannels) {

      if (this.selection.startsWith('#')) {

        const channels = this.combinedUserAndChannels.filter((selection) => selection.type === 'channel')

        if (this.selection.length > 1) {

          return channels.filter((option) => {

            const test =  option.name.toString().toLowerCase()

            return option.name
                .toString()
                .toLowerCase()
                .indexOf(this.selection.substring(1).toLowerCase()) >= 0
          })
        } else {
          return channels
        }
      }
      return this.combinedUserAndChannels.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.selection.toLowerCase()) >= 0
      })
    }
  }

  optionSelected(option: ISelection) {
    if (option) {
      if (option.type === 'user') {
        const userId = option.id

        const foundUser = this.users.find((u) => {
          return u.email === userId
        })

        if (foundUser) {
          this.$nextTick(() => {
            this.selection = ''
          })
          this.userSelected(foundUser)
        }
      } else {
        const channelId = option.id

        const foundChannel = this.channels.find((c) => {
          return c.id === channelId
        })

        if (foundChannel) {
          this.$nextTick(() => {
            this.selection = ''
          })
          this.channelSelected(foundChannel)
        }
      }
    }
  }

  @Emit('userSelected')
  userSelected(user: IUser) {
    return user
  }

  @Emit('channelSelected')
  channelSelected(channel: ISlackChannel) {
    return channel
  }
}
