












import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import TemplateCard from '@/components/TemplateCard.vue'
import { ITemplate } from '@/interfaces'

@Component({
  components: {
    TemplateCard,
  },
})
export default class TemplateList extends Vue {

  @Prop() private templates!: ITemplate[]

  get allTemplates() {
    let allTemplates: ITemplate[] = []
    const templates: ITemplate[] = [
      {
        id: '',
        title: 'Blank Form',
        recurring: false,
        description: 'Craft whatever you\'d like on a blank canvas.',
      },
    ]
    allTemplates = templates.concat(this.templates)
    return allTemplates
  }

  @Emit('createPoll')
  // tslint:disable-next-line
  createPoll() {}
}
