















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { State, Action, Getter } from 'vuex-class'
import PollCard from '@/components/PollCard.vue'
import { IPoll, IUser } from '@/interfaces'

@Component({
  components: {
    PollCard,
  },
})
export default class PollList extends Vue {

  @Prop() private polls!: IPoll[]
  @Prop() private user!: IUser

  @Emit('updatePoll')
  updatePoll(payload: any) {
    return payload
  }

  @Emit('copyPoll')
  // tslint:disable-next-line
  copyPoll() {}

  @Emit('leavePoll')
  // tslint:disable-next-line
  leavePoll() {}
}
