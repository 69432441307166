import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'
import { IRootState } from '../store'
import apolloClient from '../../apollo'
import gql from 'graphql-tag'
import { IPoll } from '@/interfaces'

export interface IPollState {
  polls?: IPoll[]
  poll?: IPoll
  error?: string
}

const namespaced: boolean = true

const pollState: IPollState = {
    polls: undefined,
    poll: undefined,
    error: '',
}

export const getters: GetterTree<IPollState, IRootState> = {
  poll: (state) => {
    return state.poll
  },
  polls: (state) => {
    return state.polls
  },
}

export const actions: ActionTree<IPollState, IRootState> = {
  async getPolls(context) {
    try {
      const result = await apolloClient.query({
        query: gql`{
          polls {
            id
            title
            description
            status
            public
            recurring
            owner {
              email
              avatar
              firstName
            }
            members {
              email
            }
            questions {
              id
              status
            }
            createdAt
          }
        }`,
      })
      return context.commit('pollsLoaded', result.data.polls)
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async getPoll(context, { pk, queryType }) {
    try {
      const result = await apolloClient.query({
        query: queryType,
          variables: {
            pk,
          },
      })
      return context.commit('pollLoaded', result.data.poll)
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async createPoll(context, { poll }) {
    try {
      const result = await apolloClient.mutate({
        mutation: gql`mutation($poll: PollInput!) {
          createPoll(poll: $poll) {
            poll {
              id
              title
              description
              status
              public
            }
          }
        }`,
        variables: {
          poll,
        },
      })
      return result.data.createPoll.poll
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async updatePoll(context, { pk, poll }) {
    try {
      const result = await apolloClient.mutate({
        mutation: gql`mutation($pk: String!, $poll: PollInput!) {
          updatePoll(pk: $pk, poll: $poll) {
            poll {
              id
              title
              description
              status
              public
              owner {
                email
                avatar
                firstName
              }
              questions {
                text
                questionType
                id
                status
                index
                choices {
                  id
                  value
                  index
                  status
                }
                answers {
                  id
                  createdAt
                  text
                  displayText
                  user {
                    avatar
                    email
                    firstName
                    lastName
                  }
                  taghighlightSet {
                    id
                    content
                    startChar
                    endChar
                    tag {
                      id
                      text
                      color
                    }
                  }
                }
              }
              outputIntegrations {
                id
                outputType
                name
                config
              },
              notifications {
                id
                type
                user {
                  email
                }
              },
              members {
                avatar
                email
                firstName
                lastName
              }
              respondents {
                avatar
                email
                firstName
                lastName
              }
            }
          }
        }`,
        variables: {
          pk,
          poll,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async copyPoll(context, { pk }) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`mutation($pk: UUID!) {
          copyPoll(pk: $pk) {
            poll {
              id
            }
          }
        }`,
        variables: {
          pk,
        },
      })
      return response.data.copyPoll.poll.id
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async updateQuestion(context, { pk, question }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: String!, $question: QuestionInput!) {
          updateQuestion(pk: $pk, question: $question) {
            question {
              id
            }
          }
        }`,
        variables: {
          pk,
          question,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async createQuestions(context, { pollPk, questions }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pollPk: String!, $questions: [QuestionInput]!) {
            createQuestions(pollPk: $pollPk, questions: $questions) {
              poll {
                id
              }
            }
          }`,
          variables: {
            pollPk,
            questions,
          },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async deleteOutputIntegration(context, { pk }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: String!){
          deleteOutputIntegration(pk: $pk){
            ok
          }
        }`,
        variables: {
          pk,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async createOutputIntegration(context, { pk, integration }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: String!, $integration: IntegrationInput!) {
          createOutputIntegration(pk: $pk, integration: $integration) {
            integration {
              name
              id
            }
          }
        }`,
        variables: {
          pk,
          integration,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async updateOutputIntegration(context, { pk, integration }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: String!, $integration: IntegrationInput!) {
          updateOutputIntegration(pk: $pk, integration: $integration) {
            integration {
              name
              id
            }
          }
        }`,
        variables: {
          pk,
          integration,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async deleteSlackNotification(context, { pk }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: String!) {
          deleteNotification(pk: $pk) {
            ok
          }
        }`,
        variables: {
          pk,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async createSlackNotifcation(context, { pk, notification }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: String!, $notification: NotificationInput!) {
          createNotification(pk: $pk, notification: $notification) {
            notification {
              id
            }
          }
        }`,
        variables: {
          pk,
          notification,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async addOwnerToPoll(context, { input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($input: MembershipInput) {
          addUserToPoll( input: $input) {
            ok
          }
        }`,
        variables: {
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async removeOwnerFromPoll(context, { input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($input: MembershipInput) {
          removeUserFromPoll( input: $input) {
            ok
          }
        }`,
        variables: {
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async addRespondentToPoll(context, { input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($input: PollAssignmentInput) {
          assignPoll( input: $input) {
            ok
          }
        }`,
        variables: {
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async removeRespondentFromPoll(context, { input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($input: PollAssignmentInput) {
          unassignPoll( input: $input) {
            ok
          }
        }`,
        variables: {
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async createTag(context, { input }) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`mutation($input: TagInput) {
          createTag( input: $input) {
            tag {
              id,
              text,
              color
            }
          }
        }`,
        variables: {
          input,
        },
      })
      return response.data.createTag
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async updateTag(context, { input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($input: TagInput) {
          updateTag( input: $input) {
            tag {
              id,
              text,
              color,
            }
          }
        }`,
        variables: {
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async createTagHighlight(context, { input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($input: TagHighlightInput) {
          createTagHighlight( input: $input) {
            tagHighlight {
              id
              content
              startChar
              endChar
            }
          }
        }`,
        variables: {
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async deleteTagHighlight(context, { pk }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: UUID!) {
          deleteTagHighlight( pk: $pk) {
            ok
          }
        }`,
        variables: {
          pk,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async sendNotificationToRespondents(context, { input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($input: SendPollNotificationsInput) {
          sendPollNotifications( input: $input) {
            ok
          }
        }`,
        variables: {
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async sendPollNotificationMessage(context, { input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($input: SendPollNotificationMessageInput){
            sendPollNotificationMessage( input: $input) {
              ok
            }
        }`,
        variables: {
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async updateResponse(context, { pk, input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: UUID!, $input: UpdateAnswerInput) {
          updateAnswer(pk: $pk, input: $input) {
            ok
          }
        }`,
        variables: {
          pk,
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async createPollFrequency(context, { pk, input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: UUID!, $input: PollFrequencyInput) {
          createPollFrequency(pk: $pk, input: $input) {
            ok
          }
        }`,
        variables: {
          pk,
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async updatePollFrequency(context, { pk, input }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: UUID!, $input: PollFrequencyInput) {
          updatePollFrequency(pk: $pk, input: $input) {
            pollFrequency {
              id
              interval
              dayOfMonth
              includeWeekend
              time
            }
          }
        }`,
        variables: {
          pk,
          input,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async deletePollFrequency(context, { pk }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: UUID!) {
          deletePollFrequency(pk: $pk) {
            ok
          }
        }`,
        variables: {
          pk,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

  async sendTestNotification(context, { pk }) {
    try {
      await apolloClient.mutate({
        mutation: gql`mutation($pk: UUID!) {
          sendTestNotification(pk: $pk) {
            ok
          }
        }`,
        variables: {
          pk,
        },
      })
    } catch (e) {
      return context.commit('pollsError', e)
    }
  },

}

export const mutations: MutationTree<IPollState> = {
  pollsLoaded(state, payload: IPoll[]) {
      state.error = ''
      state.polls = payload
  },
  pollsError(state, payload) {
      state.error = payload
  },
  pollLoaded(state, payload: IPoll) {
    state.error = ''
    state.poll = payload
  },
}

export const polls: Module<IPollState, IRootState> = {
  namespaced,
  state: pollState,
  getters,
  actions,
  mutations,
}
