











































































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { IUser, IAnswer } from '@/interfaces'
import Avatar from '@/components/Avatar.vue'

@Component({
  components: {
    Avatar,
  },
})
export default class RespondentsList extends Vue {

  menuVisible: boolean = false

  @Prop() private respondents!: IUser[]
  @Prop() private publicPoll!: boolean
  @Prop() private usersWhoHaveResponded!: IAnswer[]

  get respondentsExist() {
    if (this.respondents) {
      return (this.respondents.length > 0) ? true : false
    }
  }

  get numberOfRespondents() {
    if (this.respondents) {
      return this.respondents.length
    }
  }

  get numOfResponses() {
    if (this.usersWhoHaveResponded) {
      return this.usersWhoHaveResponded.length
    }
  }

  get numOfNonRespondents() {
    if (this.numberOfRespondents && this.numOfResponses) {
      const numOfNonRespondents = this.numberOfRespondents - this.numOfResponses
      return (numOfNonRespondents <= 0) ? 0 : numOfNonRespondents
    }
  }

  hasResponse(respondent: IUser) {
    if (this.usersWhoHaveResponded) {
      const hasResponse = this.usersWhoHaveResponded.find((answer) => {
        return answer.user.email === respondent.email
      })
      return (hasResponse) ? true : false
    }
  }

  openMenu() {
    this.menuVisible = true
  }

  closeMenu() {
    this.menuVisible = false
  }

  sendNotification() {
    this.onSendNotification(false)
  }

  sendToAllNotification() {
    this.onSendNotification(true)
  }

  @Emit('onSendNotification')
  onSendNotification(sendToAll: boolean) {
    return sendToAll
  }

  @Emit('openSettingsModal')
  openSettingsModal() {
    return 'permissions'
  }
}
