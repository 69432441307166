




















import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { ITag } from '@/interfaces'

@Component
export default class TagTab extends Vue {

  showMenu: boolean = false

  @Prop() private tagHighlight!: any

  get text() {
    if (this.tagHighlight) {
      return this.tagHighlight.tag.text
    }
  }

  @Emit('removeTagHighlight')
  removeTagHighlight() {
    return this.tagHighlight.id
  }

  @Emit('editTag')
  editTag() {
    return this.tagHighlight.tag
  }
}
