



































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { ISlackChannel } from '../store/modules/slack'

@Component
export default class ChannelList extends Vue {

  @Prop() private channels!: ISlackChannel[]

  get channelsExist() {
    if (this.channels) {
      return (this.channels[0]) ? true : false
    }
    return false
  }

  @Emit('removeChannel')
  removeChannel(channel: ISlackChannel) {
    return channel
  }
}
