




























































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { IQuestion, IPoll, STATUSES, FIELDS, IChoice } from '@/interfaces'
import { GetPollWithQuestions } from '@/queries/getPollQueries'
import PollPreview from '@/components/PollPreview.vue'
import QuestionList from '@/components/QuestionList.vue'
import maxBy from 'lodash/maxBy'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import remove from 'lodash/remove'

Component.registerHooks([
  'beforeRouteLeave',
])

const pollNamespace: string = 'polls'

@Component({
  components: {
    PollPreview,
    QuestionList,
  },
})
export default class PollQuestionsView extends Vue {
  @Getter('poll', { namespace: pollNamespace }) public poll!: IPoll

  savePollNeeded: boolean = false

  saveQuestionsNeeded: boolean = false
  changedQuestions: IQuestion[] = []

  questionsProxy: IQuestion[] = []
  firstQuestionDeleted: boolean = false

  focusNewQuestion: boolean = false

  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.saveQuestionsNeeded) {
      this.$buefy.dialog.confirm({
        message: 'You have unsaved changes. If you leave the page all unsaved changes will be lost.',
        onConfirm: () => {
          next()
        },
      })
    } else {
      next()
    }
  }

  @Watch('poll')
  onPollUpdate() {
    if (this.poll) {
      if (this.poll.questions) {
        this.questionsProxy = this.poll.questions
      }
    }
  }

  get questions() {
    if (this.poll) {
      return (this.questionsProxy.length === 0 && !this.firstQuestionDeleted) ?
      this.poll.questions : this.questionsProxy
    }
  }

  set questions(value) {
    if (value) {
      this.questionsProxy = value
    }
  }

  get questionsAtLimit() {
    if (this.questions) {
      return this.questions.filter((q) => q.status !== STATUSES.archived).length >= 5
    }
  }

  get questionsExist() {
    if (this.poll && this.poll.questions) {
      return this.poll.questions.length !== 0
    }
  }

  get previewQuestions() {
    if (this.changedQuestions.length > 0 && this.questions) {

      const changedIds = this.changedQuestions.map((q) => q.id )
      const clonedQuestions = Array.from(this.questions)

      remove(clonedQuestions, (q) => {
        return changedIds.includes(q.id)
      })

      return clonedQuestions.concat(this.changedQuestions)

    } else {
      return this.questions
    }
  }

  get nextIndex() {
    if (this.questions) {
      return this.questions.length + 1
    }
    return 1
  }

  refreshPoll() {
     this.$store.dispatch('polls/getPoll', {
      pk: this.$route.params.pollId,
      queryType: GetPollWithQuestions,
    })
  }

  addQuestion() {
    if (!this.questionsAtLimit) {
      if (this.questions) {
        this.questionsProxy = this.questions
      }

      const newQuestion = {
        questionType: FIELDS.textfield,
        text: '',
        status: STATUSES.active,
        id: 'new' + this.nextIndex,
        index: this.nextIndex,
        choices: [],
      }

      if (this.poll.questions) {
        Vue.prototype.$ma.trackEvent({
          action: 'Question added to form',
          properties: {
            questionType: newQuestion.questionType,
            questionNumber: this.poll.questions.length + 1,
          },
        })
      }

      this.questionsProxy.push(newQuestion)
      this.focusNewQuestion = true
      this.updateQuestion()
    }
  }

  updateQuestion(question?: IQuestion) {
    this.saveQuestionsNeeded = true
    if (question) {

      if (question.id.substring(0, 3) !== 'new') {

        const foundIndex = this.changedQuestions.findIndex((q) => q.id === question.id)

        if (foundIndex !== -1) {
          this.changedQuestions.splice(foundIndex, 1, question)
        } else {
          this.changedQuestions.push(question)
        }
      } else {

        const foundIndex = this.questionsProxy.findIndex((q) => q.id === question.id)

        if (foundIndex !== -1) {
          this.questionsProxy.splice(foundIndex, 1, question)
        } else {
          this.questionsProxy.push(question)
        }
      }
    }
  }

  removeUnsavedQuestion(question: any) {
    if (this.questionsProxy.length === 1) {
      this.firstQuestionDeleted = true
    } else {
      this.firstQuestionDeleted = false
    }
    this.questionsProxy = this.questionsProxy.filter((q: any) => {
      return q.id !== question.id
    })
  }

  removeTypeNameFromChoices(question: IQuestion) {
    return question.choices.map((c) => {
      return pick(c, ['value', 'id', 'status', 'index'])
    })
  }

  areQuestionsValid(questions: IQuestion[]) {
    let badQuestionMessage = ''

    if (questions) {
      questions.map((q: IQuestion) => {
        if (q.text === '') {
          badQuestionMessage = 'Questions cannot be blank'
        }

        if (q.questionType === FIELDS.dropdown) {
          const badChoices = q.choices.find((c: IChoice) => {
            return c.value === ''
          })
          if (badChoices) {
            badQuestionMessage = 'Options cannot be blank'
          }
        }

      })
      if (badQuestionMessage !== '') {
        this.$buefy.toast.open({
            message: badQuestionMessage,
            type: 'is-danger',
        })
        return false
      }
      return true
    }
  }

  filterActiveQuestions() {
    if (this.questions) {
      const activeQuestions = this.questions.filter((q) => {
        return q.status === STATUSES.active && q.id.substring(0, 3) !== 'new'
      })
      return activeQuestions
    }
    return []
  }

  async save() {

    let errorsTriggered: boolean = false

    if (this.saveQuestionsNeeded && this.questions) {

      const newQuestions = this.questions.filter((q) => q.id.substring(0, 3) === 'new')

      if (newQuestions.length !== 0) {
        if (this.areQuestionsValid(newQuestions)) {
          await this.createNewQuestions(newQuestions)
        } else {
          errorsTriggered = true
        }
      }

      if (this.changedQuestions.length !== 0) {
        if (this.areQuestionsValid(this.changedQuestions)) {
          await this.updateExistingQuestions()
        } else {
          errorsTriggered = true
        }
      }
    }

    if (!errorsTriggered) {
      this.refreshAfterSave()
    }
  }

  async refreshAfterSave() {
    this.refreshPoll()

    this.$buefy.toast.open({
        message: 'Your changes have been saved!',
        type: 'is-success',
    })
    this.saveQuestionsNeeded = false
  }

  async deleteQuestion(question: IQuestion) {

    const activeQuestions: IQuestion[] = this.filterActiveQuestions()

    if (activeQuestions.length !== 1) {

      question.choices = this.removeTypeNameFromChoices(question)
      question.status = STATUSES.archived
      await this.$store.dispatch('polls/updateQuestion', {
        pk: question.id,
        question: pick(question, ['questionType', 'text', 'index', 'choices', 'status']),
      })

    } else {
      this.$buefy.toast.open({
          message: 'Your form must have at least one question',
          type: 'is-danger',
      })
    }
    await this.$store.dispatch('polls/getPoll', {
      pk: this.$route.params.pollId,
      queryType: GetPollWithQuestions,
    })
  }

  async updateExistingQuestions() {
    const createdPromises: any = []
    this.changedQuestions.map((question) => {
      question.choices = this.removeTypeNameFromChoices(question)
      createdPromises.push(this.$store.dispatch('polls/updateQuestion', {
        pk: question.id,
        question: pick(question, ['questionType', 'text', 'index', 'choices', 'status']),
      }))
    })
    await Promise.all(createdPromises)
  }

  async createNewQuestions(newQuestions: IQuestion[]) {
    const pollPk = this.poll.id
    const payload = {
      pollPk,
      questions: newQuestions.map((q: IQuestion) => {
        return omit(q, ['id'])
      }),
    }
    await this.$store.dispatch('polls/createQuestions', payload)
  }

  async deactivatePoll() {
    await this.$store.dispatch('polls/updatePoll', {
      pk: this.poll.id,
      poll: {
        status: STATUSES.inactive,
      },
    })
  }
}
