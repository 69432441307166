


















import { Component, Vue, Emit, Prop } from 'vue-property-decorator'

@Component
export default class StatusFilter extends Vue {

  status: string = ''

  @Prop() private placeholder!: string
  @Prop() private disabled!: boolean

  get filteredStatus() {
    const pollStatuses = [
      'Active',
      'Archived',
    ]
    return pollStatuses.filter((option) => {
        return option
            .toString()
            .toLowerCase()
            .indexOf(this.status.toLowerCase()) >= 0
    })
  }

  @Emit('statusSelected')
  statusSelected(status: string) {
    return status.toLowerCase()
  }
}
